.TimmerFinishedSec{
    display: flex;
    justify-content: center;
}
/* .TimmerFinishedButton {
    display: block;
    background: #FA9600;;
    border-radius: 3px;
    border: none;
    width: 43rem;
    padding: .5rem 0;
    color: white;
    text-decoration: none;
    text-align: center;
} */
.TimmerFinishedButton{
    width: 99%; 
    margin-bottom: 1rem;
    font-size: 14px;
    color: #FFFFFF;
    text-align: center;
    text-decoration: none;
    background-color: #FA9600;
    border: none;
    color: white;
    box-shadow: 2px 2px 2px #fa960033;
    padding: .5rem 0;
    border-radius: 3px;
}
.TimmerFinishedButton:hover{
    color: #FFFFFF;
    box-shadow: 1px 1px 10px -1px #FA9600dd;
}
.TimmerFinishedButton:disabled{
    background-color: #d2a561;
}
@media (max-width: 25.875rem){
    .TimmerFinishedSec{
        text-align: center;
    }
    .TimmerFinishedButton{
        background: #FA9600;;
        border-radius: 3px;
        border: none;
        width: 13.5rem;
        padding: .5rem 0;
    }

}