.backgroundColor{
    background-color: #55DDE0;
    height: 89.9vh;
}
.title{
    font-style: normal;
font-weight: 700;
font-size: 64px;
line-height: 77px;
text-align: center;
margin-bottom: 3rem;
}
.buttonSec{
    width: 100%;
    text-align: center;
}
.button{
    width: 13.25rem;
    background: #1E8C96;
    border-radius: 3px;
    border: none;
    color: white;
    font-weight: 300;
    font-size: 1.5rem;
    line-height: 143.52%;
}
.verticle{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 89.9vh;
}

@media (max-width: 25.875rem){
    .title{
        font-weight: 700;
        font-size: 2rem;
        line-height: 2.438rem;
    }

}