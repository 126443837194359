.box{
    width: 100%;
    height: 2rem;
    margin: auto;
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    background-color: #ffffff;
    position: relative;
}
.progress{
    width: 0;
    height: 2rem;
    background-color:  #EF6419;
    /* border-radius: 15px; */
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    margin: 0;
    /* --mask:
    radial-gradient(30px at 100% 25%,#0000 98%,#000) calc(100% - 30px) 50%/100% 120px repeat-y,
    radial-gradient(30px at 50% 75%,#000 99%,#0000 101%) right/60px 120px repeat-y;
    -webkit-mask: var(--mask);
          mask: var(--mask); */
   
    /* clip-path: polygon(0% 0%, 100% 0,74% 14%,73% 13%,72% 12%, 70% 20%, 100% 100%, 0% 100%); */
    
}
.wave{
    background-color: white;
    width: 50px;
    height: 50px;
    animation: wavespin 5s linear -5s infinite;
    position: absolute;
    top: -16px;
    border-radius: 30% 70% 43% 57% / 30% 34% 66% 70% ;
}

@keyframes wavespin {
    50% {
    transform:  rotate(180deg);
    
    }
    100% {
    transform:  rotate(360deg);
    }
}
.finishedDays{
    position: absolute;
    left: 30vw;
    bottom: -39%;
    font-size: 1vw;
    font-weight: 300;
}

@media (max-width: 540px){
    .finishedDays{
        position: absolute;
        left: 30vw;
        bottom: -30%;
        font-size: 1rem;
        font-weight: 300;
    }

}