/* 彈出視窗邊界 */
.modal-body{
    padding: .5rem .5rem .5rem;
}
.popupMuiTable .MuiDataGrid-virtualScrollerRenderZone{
    transform: none!important;
    width: 100%;
}
.popupMuiTable .MuiDataGrid-menuIcon{
    display: none!important;
}
.popupMuiTable div[data-field=id]{
    display: none!important;
}
.popupMuiTable .MuiDataGrid-root{
    border: none!important;
    width: 100%;
    max-width: 667px;
    height: 100%!important;
}
.popupMuiTable .MuiDataGrid-root .MuiDataGrid-columnsContainer{
    margin: auto!important;
}
.popupMuiTable .MuiDataGrid-footerContainer{
    display: none!important;
}
.popupMuiTable .MuiDataGrid-cell{
    text-align: center!important;
    justify-content: center!important;
    width: 83px!important;
    min-width: 83px!important;
    min-height: 83px!important;
}
.popupMuiTable .MuiDataGrid-row{
    margin: auto;
    margin-left: 0px!important;
    min-height: 83px!important;
}
.popupMuiTable .MuiDataGrid-row:nth-child(1){
    border-top: 1px solid rgba(224, 224, 224, 1);
}
.popupMuiTable .MuiDataGrid-virtualScroller{
    width: 360px!important;
    height: 150px!important;
}
.popupMuiTable .MuiDataGrid-virtualScrollerContent{
    width: 100%!important;
    height: 100%!important;
}
.popupMuiTable .MuiDataGrid-columnHeaders{
    border: none!important;
    width: 100%;
    max-width: 976px!important;
    margin: auto 0;
    justify-content: left;
}
.popupMuiTable .MuiDataGrid-columnHeader{
    width: 83px!important;
    min-width: 83px!important;
}
.popupMuiTable .MuiDataGrid-cellContent{
    width: 100%!important;
    white-space: initial;
}
.popupMuiTable .table-container{
    width: initial;
    margin-left: 14%;
    max-width: 976px;
    height: 210px;
}
.popupMuiTable .table-img{
    padding: 5px;
} 

.MuiDataGrid-main{
    -ms-overflow-style: none!important;  /* IE and Edge */
    scrollbar-width: none!important;   /* Firefox */
}
.MuiDataGrid-main::-webkit-scrollbar{
    display: none!important; 
}
.MuiDataGrid-columnHeaders{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.MuiDataGrid-columnHeaders::-webkit-scrollbar{
    display: none; /* Chrome */
}
.MuiDataGrid-virtualScroller{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.MuiDataGrid-virtualScroller::-webkit-scrollbar{
    display: none; /* Chrome */
}
  @media only screen and (max-width: 976px) {
    .popupMuiTable .MuiDataGrid-columnHeadersInner{
        transform: none!important;
    }
  }
    @media screen and (max-width: 450px) {
        .popupMuiTable .table-container{
            margin-left: 3%;
        }
    }
    @media only screen and (max-width: 375px) {
        .popupMuiTable .MuiDataGrid-virtualScroller {
            width: 375px!important;
        }
        /* .popupMuiTable .MuiDataGrid-columnHeaders{
            overflow: visible!important;
        }
        .popupMuiTable .MuiDataGrid-main{
            overflow-x: auto!important;
        } */
    }