.MuiDataGrid-virtualScrollerRenderZone{
    transform: translate3d(87px, 0px, 0px)!important;
}
.MuiDataGrid-menuIcon{
    display: none!important;
}
div[data-field=id]{
    display: none!important;
}
.MuiDataGrid-root{
    border: none!important;
}
.MuiDataGrid-root .MuiDataGrid-columnsContainer{
    /* // width: 96.5%!important;  */
    margin: auto!important;
}
.MuiDataGrid-footerContainer{
    display: none!important;
}
/* .MuiDataGrid-root .MuiDataGrid-window {
    overflow: hidden!important;
} */
.MuiDataGrid-cell{
    text-align: center!important;
    justify-content: center!important;
}

.MuiDataGrid-row {
    margin: auto;
}
.MuiDataGrid-row:nth-child(1) {
    border-top: 1px solid rgba(224, 224, 224, 1);
}
.MuiDataGrid-columnHeaders{
    overflow: visible!important;
}
.MuiDataGrid-main{
    overflow: auto!important;
}
.MuiDataGrid-virtualScroller{
    overflow: initial!important;
    width: 890px!important;
    height: 150px!important;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.MuiDataGrid-virtualScroller::-webkit-scrollbar{
    display: none; /* Chrome */
}

/* .css-yrdy0g-MuiDataGrid-columnHeaderRow div:nth-last-child(1) svg {
    display: none!important;
} */
.MuiDataGrid-virtualScrollerContent{
    width: fit-content!important;
}
/* .MuiDataGrid-virtualScroller{
    width: 640px!important;
} */
/* .MuiDataGrid-virtualScroller.css-1w5m2wr-MuiDataGrid-virtualScroller */
.MuiDataGrid-virtualScrollerRenderZone {
    width: 100%;
}
.MuiDataGrid-columnHeaders {
        border: none!important;
        width: 100%;
        max-width: 976px!important;
        margin: auto 0 auto 87px;
        /* justify-content: center!important; */
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
}
.MuiDataGrid-columnHeaders::-webkit-scrollbar{
    display: none; /* Chrome */
}
.MuiDataGrid-columnHeader{
    width: 200px!important;
    min-width: 200px!important;
}
.MuiDataGrid-cell{
    width: 200px!important;
    min-width: 200px!important;
}
.MuiDataGrid-cellContent{
    width: 100%!important;
    white-space: initial;
}
.table-container {
    margin: 0 auto;
    max-width: 976px;
    height: 210px;
}
.table-img{
    padding: 5px;
} 
.MuiDataGrid-root{
    height: 82%!important;
}
/* .MuiDataGrid-columnHeaders{
    overflow: visible!important;
} */
.MuiDataGrid-main{
    -ms-overflow-style: none!important;  /* IE and Edge */
    scrollbar-width: none!important;   /* Firefox */
}
.MuiDataGrid-main::-webkit-scrollbar{
    display: none!important; 
}

@media screen and (max-width: 1125px)  {
    .MuiDataGrid-virtualScrollerRenderZone{
        transform: translate3d(0px, 0px, 0px)!important;
    }
    .MuiDataGrid-columnHeaders {
        margin: auto 0 auto 0;
    }
}
  @media only screen and (max-width: 976px) {
    /* .MuiDataGrid-columnHeaders.css-z8fhq1-MuiDataGrid-columnHeaders {
        margin: auto 0 auto 45px!important;
    } */
    .MuiDataGrid-row{
        margin-left: 0px!important;
    }
    .MuiDataGrid-columnHeadersInner{
        transform: none!important;
    }
    .MuiDataGrid-virtualScroller {
        width: 767px!important;
        /* overflow: initial!important; */
    }
    .MuiDataGrid-virtualScrollerRenderZone{
        transform: none!important;
    }
    .table-container .MuiDataGrid-root {
      width: 100%;
    }
    .MuiDataGrid-columnHeaders {
        width: 100%;
        margin: auto 0 auto 0;
        justify-content: center;
    }
    .MuiDataGrid-columnHeader{
        width: 160px!important;
        min-width: 160px!important;
    }
    .MuiDataGrid-cell{
        width: 160px!important;
        min-width: 160px!important;
    }
  }
  @media screen and (max-width: 884px) {
  }
  @media only screen and (max-width: 767px) {
    .MuiDataGrid-root{
        height: 100%!important;
    }
    .table-container{
        width: 63%;
        margin: auto;
    }
    .MuiDataGrid-cellContent{
        width: 100%!important;
        white-space: initial;
    }
    .MuiDataGrid-virtualScrollerContent {
        width: 100%!important;
    }
    .MuiDataGrid-virtualScrollerContent{
        /* height: 450px!important; */
        height: 100%!important;
    }
    .MuiDataGrid-columnHeaders {
        margin: auto 0 auto 0!important;
    }
    .table-container .MuiDataGrid-root {
      width: 100%;
      max-width: 976px;
    }
    .MuiDataGrid-columnHeaders {
        margin: auto 0;
    }
    /* .MuiDataGrid-row{
        margin-left: 62px!important;
    }
    .table-container .MuiDataGrid-root {
        width: 100%;
        max-width: 767px;
    } */
    /* .MuiDataGrid-virtualScroller.css-1w5m2wr-MuiDataGrid-virtualScroller {
        width: 767px!important;
        height: auto!important;
    } */
    /* .MuiDataGrid-columnHeader{
        width: 150px!important;
        min-width: 150px!important;
    }
    .MuiDataGrid-cell{
        width: 150px!important;
        min-width: 150px!important;
    } */
    .MuiDataGrid-columnHeaders {
        justify-content: left;
    }
    .MuiDataGrid-columnHeader{
        width: 83px!important;
        min-width: 83px!important;
    }
    .MuiDataGrid-cell{
        width: 83px!important;
        min-width: 83px!important;
    }
    .table-container .MuiDataGrid-root {
      width: 100%;
      max-width: 767px;
    }
    .MuiDataGrid-cellContent{
        width: 100%!important;
        white-space: initial;
    }
    .MuiDataGrid-virtualScroller {
        width: 380px!important;
        /* overflow: initial!important; */
    }
    .MuiDataGrid-virtualScrollerContent {
        width: 100%!important;
        height: 100%!important;
    }
    .MuiDataGrid-row, .MuiDataGrid-cell{
        min-height: 83px!important;
    }
  }
  
  @media only screen and (max-width: 480px) {
    .table-container{
        width: initial;
        margin-left: 3%;
    }
    .MuiDataGrid-columnHeaders {
        justify-content: left;
    }
    .MuiDataGrid-columnHeader{
        width: 83px!important;
        min-width: 83px!important;
    }
    .MuiDataGrid-cell{
        width: 83px!important;
        min-width: 83px!important;
    }
    .table-container .MuiDataGrid-root {
      width: 100%;
      max-width: 767px;
    }
    .MuiDataGrid-cellContent{
        width: 100%!important;
        white-space: initial;
    }
    .MuiDataGrid-virtualScroller {
        width: 480px!important;
        /* overflow: initial!important; */
    }
    .MuiDataGrid-virtualScrollerContent {
        width: 100%!important;
        height: 100%!important;
    }
    .MuiDataGrid-row, .MuiDataGrid-cell{
        min-height: 83px!important;
    }
    @media only screen and (max-width: 375px) {
        .MuiDataGrid-virtualScroller {
            width: 375px!important;
            overflow: initial!important;
        }
        .MuiDataGrid-columnHeaders{
            overflow: visible!important;
        }
        .MuiDataGrid-main{
            overflow: auto!important;
        }
    }
  }