html, body {
    height:100%;
    width:100%;
    margin:0;
}
.container_fluid{
    padding: 0 !important;
    margin: 0 !important;
}
.bannerColPC{
    padding-top: 1rem;
}
.bannerColPhone{
    padding-top: 0;
}
.bannerTitleImgDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.bannerImgDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.twoMemberCard{
    width: 360px;
    margin-top: 25px;
    z-index: 5;
}
.bannerBottomImgDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.bannerBottomImg{
    width: 340px;
    margin-top: 25px;
    margin-left: -70px;
}
.bannerSec{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    width: fit-content;
    /* padding: 4rem 0; */
    padding: 20px 0 40px;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
}
.bannerPC3{
    width: 100%;
}
.bannerPhone3{
    width: 100%;
    display: none;
}
.adver1Div{
    z-index: 0;
    width: 18%;
}
.adver4Div{
    width: 20%;
}
.adver4DivDiv{
    position: absolute;
    /* top: 14.5%; */
    top: 18.5%;
    right: 13%;
    z-index: 0;
    width: 180px;
}
.adver1DivImgDiv{
    /* width: 195px;
    height: 215px; */
    width: 270px;
    height: 300px;
    margin-left: 40px;
    padding-top: 10%;
    border-radius: 15px;
    /* background: #AAEBEB; */
    display: flex;
    text-align: center;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}
.adver4DivTitleDiv {
    width: 185px;
    height: 180px;
    margin: auto;
    padding-top: 10%;
    border-radius: 15px;
    background: #FFC83C;
    display: flex;
    text-align: center;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}
/* .adver4DivTitleDiv{
    width: 75%;
    height: 200px;
    margin: auto;
    padding-top: 20px;
    background: #FFC83C;
    display: flex;
    text-align: center;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
} */
.adver1{
    z-index: 5;
    width: 280px;
    position: absolute;
    top: -5%;
    left: 0%;
}
.adver2{
    position: absolute;
    /* top: 26%;
    left: 30%; */
    /* top: 255px;
    left: 410px; */
    top: 52%;
    left: 51%;
    z-index: 10;
    /* width: 25%; */
    width: 350px;
}
.adver3{
    position: absolute;
    top: 39%;
    right: 40%;
    z-index: 10;
    /* width: 20%; */
    width: 270px;
}
.adver4{
    position: absolute;
    /* top: 21.8%;
    right: 8%; */
    z-index: 5;
    /* width: 200px; */
    top: -2%;
    right: 0%;
    width: 180px;
}
.right2Img{
    position: absolute;
    z-index: 0;
    top: 91%;
    right: 9%;
    width: 180px;
}
.leftImg{
    position: absolute;
    z-index: 0;
    top: 140%;
    left: 9%;
    width: 240px;
}
.bottomImgDiv{
    width: 100%;
    display: flex;
}
.bottomImg{
    /*
    bottom: -75%;
    left: 12%;
    width: 720px; */
    /* position: relative;
    z-index: 0;
    bottom: 60%;
    left: 20%; */
    width: 60%;
    margin: auto;
}
.adver5{
    width: 150px;
    position: absolute;
    top: -100px;
    left: 75%;
}
.adver5Phone{
    display: none;
}
.adver6{
    width: 240px;
    position: absolute;
    top: -127px;
    right: 85%;
}
.adver15{
    width: 400px;
    position: absolute;
    top: 100px;
    right: -30%;
}
.layoutComp{
    z-index: 10;
    width: 80%;
    background-color: #FFFFFF00;
    align-items: center;
    /* margin-right: 36px; */
    margin-left: 36px;
}
.layoutPhone{
    z-index: 0;
    width: 50%;
    background-color: #fff;
    align-items: center;
    display: none;
}
.titleH1{
    position: relative;
    margin: 0 auto 6px!important;
    font-family: 'Arial';
    font-style: normal;
    text-align: center;
    color: #FA9600!important;
    z-index: 10;
    width: fit-content;
}
.titleH2{
    position: relative;
    margin: 0 auto!important;
    font-family: 'Roboto';
    font-style: normal;
    text-align: center;
    color: #FA9600!important;
    z-index: 10;
    width: fit-content;
}
.titleH2_2{
    position: relative;
    margin: 0 auto!important;
    font-family: 'Roboto';
    font-style: normal;
    text-align: center;
    color: #33658A!important;
    z-index: 10;
    width: fit-content;
}
.titleH3{
    position: relative;
    margin: 0 auto!important;
    color: #000!important;
    text-align: center;
    z-index: 10;
    width: fit-content;
}
.titleH3_2{
    position: relative;
    font-size: 28px!important;
    margin: 0 auto 20px!important;
    color: #33658A!important;
    text-align: center;
    z-index: 10;
    width: fit-content;
}
.titleH3_3{
    font-size:20px!important;
    position: relative;
    margin: 24px 0 20px 160px!important;
    color: #ADADAD!important;
    text-align: center;
    z-index: 10;
    background-color: #FFFFFF88;
    width: 275px;
    border-radius: 3px;
    padding: 5px;
}
.titleH3_4{
    position: relative;
    margin:auto!important;
    color: #333333!important;
    text-align: center;
    z-index: 10;
    background-color: #FFFFFF88;
    width: 275px;
    border-radius: 3px;
    padding: 16px;
}
.activeTitleDivPC{
    display:block;
}
.activeTitleDivPhone{
    display:none;      
}
.titleH3_5{
    position: relative;
    font-size: 28px!important;
    margin: 0 auto 20px!important;
    color: #FA9600!important;
    text-align: center;
    z-index: 10;
    width: fit-content;
    background-color: #FFFFFF88;
}
.titleH3_6{
    position: relative;
    margin:auto!important;
    color: #333333!important;
    text-align: center;
    z-index: 0;
    background-color: #FFFFFF88;
    width: 275px;
    border-radius: 3px;
    padding: 16px;
}
.titleH4{
    position: relative;
    margin: 0 auto!important;
    color: #000!important;
    text-align: center;
    z-index: 10;
    background-color: #FFFFFF88;
    width: fit-content;
}
.titleH5{
    font-size: 14px!important;
    font-weight: 600!important;
    color: #333;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 10%;
    background-color: #FFFFFF88;
}
.titleH5 a{
    color: #333!important;
}
.titleH5 a:hover{
    text-decoration: underline;
}
.lineBindFriendDiv{
    display: flex;
}
.inline{
    display: inline-block;
    font-size: 18px;
}
.inlineTitle{
    display: inline-block;
    font-size: 18px;
    font-weight: 600!important;
}
.memberTableCol{
    position: relative;
    z-index: 1;
    background-color: #FFFFFF00;
}
.grantMembershipSec{
    position: relative;
    z-index: 1;
    /* background-color: #FFFFFF88; */
}
.orangeLineDiv{
    width: 100%;
}
.orangeLine{
    display: block;
    width: 77%;
    margin: auto;
    position: relative;
    z-index: 5;
    margin-left: 10%;
}
/* .congratesSecONE{
    width: 60vw;
    box-sizing: border-box;
    margin-bottom: 1rem;
    padding: 0 .5rem 1rem .5rem;
    margin: auto;
}
.congratesSecONE P{
    margin: 0;
    color: #585858;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 143.52%;
} */
.congratesSecOne{
    position: relative;
    width: 38%;
    display: flex;
    flex-direction: column;
    /* width: 60vw; */
    box-sizing: border-box;
    padding: 48px;
    margin-left: 15%;
    background-color: #FFC83C55;
    border-radius: 15px;
}
.congratesSecOne p{
    margin: 0;
    color: #585858;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    /* line-height: 143.52%; */
    line-height: 48px;
}
.congratesSecTwo{
    position: relative;
    top: -100px;
    width: 38%;
    display: flex;
    flex-direction: column;
    /* width: 60vw; */
    box-sizing: border-box;
    padding: 48px;
    /* margin: auto; */
    margin-left: 50%;
    background-color: #AAEBEB55;
    border-radius: 15px;
}
.congratesSecTwo p{
    margin: 0;
    color: #585858;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 48px;
}
.congratesSecThree{
    position: relative;
    top: -80px;
    width: 50%;
    display: flex;
    flex-direction: column;
    /* width: 60vw; */
    box-sizing: border-box;
    padding: 48px;
    /* margin: auto; */
    margin-left: 22%;
    margin-bottom: 48px;
    background-color: #FA960033;
    border-radius: 15px;
}
.congratesSecThree p {
    margin: 0;
    color: #585858;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 48px;
}
.congratesSecFour{
    width: 400px;
    display: flex;
    flex-direction: column;
    /* width: 60vw; */
    box-sizing: border-box;
    margin-bottom: 24px;
    padding: 48px;
    margin: 0 auto 1rem auto;
}
.congratesSecFour p{
    margin: 0;
    color: #1E8C96;
    font-size: 20px;
    font-weight: bold;
}
.courseDiv{
    display: flex;
    margin-bottom: 2rem;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 2rem;
}
.courseDiv div{
    width: 100%;
    text-align: center;
}
.courseDiv div img{
    width: 90%;
}
.stepCircle{
    background-color: #1E8C96;
    border-radius: 50%;
    width: 42px;
    height: 42px;
    text-align: center;
}
.stepCircle p {
    width: 42px;
    height: 42px;
    font-family: 'Inter';
    font-style: normal;
   font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: white;
}

/* 舊版型 */
.lesson {
    --x: 0;
    --y: 0;
    --angle: 0deg;
    transform: rotate3d(var(--x), var(--y), 0, var(--angle));
}
.container_customMargin{
    box-sizing: border-box;
    padding: 0 10vw !important;
}
.Stepsflex1{
    display: block;
}
.StepsTitle{
    color: #1E8C96;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    border-bottom:4px solid #1E8C96;
    box-sizing: border-box;
    margin-bottom: 2rem;
    padding: 0;
    text-align: center;
}
.StepsTitle p{
  box-sizing: border-box;
  margin: 0 auto .3rem  auto;  
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
}
.StepsCardCol{
    display: flex;
}
.StepsCard, .StepsCardShareCert{
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 1rem;
    padding: 1rem;
    /* border: 3px solid #AAEBEB;
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.03); */
    border-radius: 10px;
    margin-right: 10px;
}
.StepsCardShareCert{
    margin-bottom: 0;
    padding: 0;
}
/* .StepsCardflex{ */
    /* display: flex;
    justify-content: space-between; */
/* } */
.StepsCardTitle p{
    color: #FA9600;
    font-style: normal;
    font-weight: bolder;
    font-size: 30px;
    line-height: 36px;
}
.StepsCardDescription p{
    color: #585858;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 143.52%;
    font-family: 'Inter';
    padding-right: .5rem;
    
}
.StepsCardImgSec{
   width: 350px;
}
.StepsCardImg{
    width: 100%;
}
.StepTwoCardDescription, .StepThreeCardDescription{
    padding: 0 1rem 1rem 1rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 143.52%;
}
.StepTwoCardDescription p, .StepThreeCardDescription p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    /* line-height: 143.52%; */
    line-height: 32px;
    color: #585858;
    margin-bottom: 12px;
}
.StepTwoFLEX{
  display: flex;
  justify-content: space-between;
}
/* 貼上以下文字 */
.StepThreeDiv p{
    margin-bottom: 12px;
}
.StepThreeFLEX1{
    display: flex;
    margin-bottom: 1rem;
}
.StepThreePStar{
    flex:1;
    margin-top: 7px;
}
.StepThreeFLEX2{
    flex:29;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
}
.StepThreeFLEX2 p{
    /* margin-bottom: 12px; */
    display: flex;
    height: 48px;
    align-items: center;
}
.StepThreeDivContent, .StepThreeDivOr{
    flex: 1;
    margin-right: 100px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.StepThreeDivContent img{
    width: 165px;
    height: 165px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
}
.StepThreeDivOr p{
    color: #FFC83C;
    font-size: 18px;
}
.StepThreeFLEX p{
    display: flex;
}
.StepTwoP{
    margin: 0;
}

.StepTwoCardDescription a{
    text-decoration: none;
    color: #1400FF;
}
.StepTwoCardDescriptionOne{
    color: #585858;
    font-weight: 600!important;
    font-size: 20px;
}
.howtoShareCertificateLink{
    text-decoration: none;
    color: #1400FF;
}
.howtoShareCertificateLink:hover{
    color: #1400FF88;
}
.StepThreeDescriptionList{
    box-sizing: border-box;
    border-radius: 6px;
    padding: .5rem 1rem .5rem 0;
    color: #585858;
    font-size: 1rem;
    font-weight: 500;
}
.StepThreeDescriptionList li{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 143.52%;
    color: #585858;
}
.StepThreeDescriptionListSpan{
    color: #FA9600;
}
.StepThreeCopyFLEX{
    display: flex;
    width: 100%;
    border-radius: 3px;
    margin-bottom: 32px;
}
.StepThreeCopy{
    flex: 14;
    border: 1px solid #EBEBEB;
    border-radius: 3px;
    padding: 12px;
    /* margin-bottom: 12px; */
    background-color: #EFEFEF;
}
.StepThreeCopy p{
   font-family: 'Inter';
   font-style: normal;
   font-weight: 500;
   font-size: 20px;
   line-height: 143.52%;
   color: #000000;
   margin: 0;
}

.CopySec{
    flex: 2;
    display: flex;
    align-items: center;
    text-align: center;
    background-color: #EFEFEF;
    /* margin-bottom: 12px; */
    padding: 12px;
}

.CopyButton{
    border: 1px solid #1E8C96;
    background-color: #FFFFFF00;
    color: #1E8C96;
    height: 85%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 143.52%;
    /* background: #1E8C96; */
    border-radius: 3px;
    text-align: center;
    /* color: white; */
    /* padding: 2px 5px; */
    width: 100%;
}
.CopyButton:hover{
    /* box-shadow: 1px 1px 10px -1px #1E8C96; */
    border: 2px solid #FFFFFF;
    background-color: #1E8C96;
    color: #FFFFFF;
}
.lookSbSay{
    border: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 143.52%;
    background: #1E8C96;
    border-radius: 3px;
    text-align: center;
    color: white;
    /* padding: 2px 5px; */
    width: 100%;
    height: 100%;

}
.lookSbSay:hover{
    box-shadow: 1px 1px 10px -1px #1E8C96;

}
.StepsThreeWidth{
    width: fit-content;
    background-color: #EBEBEB;
}
/* 分享該注意甚麼? */
.StepThreeCopyAtt{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.StepThreeCopyAtt p{
    /* margin-right: 12px; */
    margin-bottom: 5px;
    color:#1400FF;
    flex: 1;
    text-align: right;
}
.StepThreeCopyAtt button{
    flex: 1;
    /* margin-right: 23%; */
    font-size: 20px;
    padding: 0.5rem 5rem;
    width: fit-content;
}
.StepsCardONEflex{
    box-sizing: border-box;
    display: flex;
    padding: .5rem .5rem 1rem .5rem;
}
.lastSEC{
    /* height: 200px;
    text-align: end;
    padding:  6rem 0 0 0 ; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
    height: 130px;
}
.lastSECSPAN{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 2vw;
    line-height: 2.5rem;
    color: #585858;

}
.SubmitSec{
    box-sizing: border-box;
    text-align: center;
    width: fit-content;
    display: inline-block;
}
.SubmitButton{
    box-sizing: border-box;
    /* padding: 0 1vw; */
    padding: 0;
    /* margin: 10px; */
    background-color: #FA9600;
    width: 20vw;
    color: white;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    /* font-size: 48px; */
    line-height: 48px;
    border: none;
    border-radius: 3px;
}
.SubmitButton:hover{
    box-shadow: 1px 1px 10px -1px #FA9600;
}

.whereisCertificatePhone{
    display: none;
}
.StepThreeCopyAttTextPC{
    line-height: 32px!important;
    margin-top: 30px;
}
.StepThreeCopyAttTextPhone{
    display: none;
}
.howtoShareCertificatePC{
    list-style-type: none;
    line-height: 32px!important;
}
.howtoShareCertificatePhone{
    list-style-type: none;
    opacity: 0;
    font-size: 0;
    line-height: 0;
    height: 0;
}
.WarningSec{
    margin: 0;
    padding: 1.5rem 0 3rem;
    text-align: center;
}
.WarningSec p{
    font-family: 'Inter';
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    color: #585858;
    margin: 0;
    padding: 0;
 
}
.h_iframe_pc {
    display: block;
    height: 100%;
    width:100%;
    text-align: center!important;
}
.h_iframe_phone {
    display: none;
}
.h_iframe_pc iframe {
    width: 60%;
    height: 25vw;
    border-radius: 3px;
    margin-top: 35px;
}
    @media screen and (max-width: 1200px) {
        .titleH5{
            margin-right: 10px;
        }
    }
    @media screen and (max-width: 967px) {
    .bannerColPC{
        padding-top: 1rem;
    }
    .bannerColPhone{
        padding-top: 0;
    }
    .h_iframe_pc {
        display: none;
    }
    .h_iframe_phone {
        display: block;
        height: 100%;
        width:100%;
        text-align: center;
    }
    .h_iframe_phone iframe {
        width: 100%;
        height: 41vw;
        border-radius: 3px;
        margin-top: 35px;
    }
    .twoMemberCard{
        width: 270px;
        margin-top: 15px;
    }
    .bannerBottomImgDiv{
        display: flex;
        flex-direction: column;
    }
    .bannerBottomImg{
        margin: -25px 0px 0 60px
    }
    .bannerSec{
        padding: 35px 0 16px;
    }
    .layoutComp{
        margin-right: 40px;
        /* margin-right: -5px; */
    }
    .titleH1{
        font-size: 28px!important;
    }
    .titleH2, .titleH2_2{
        font-size: 24px!important;
    }
    .titleH3{
        font-size: 20px!important;
    }
    .titleH3_2{
        margin-bottom: 10px!important;
    }
    .titleH3_3{
        font-size: 18px!important;
        margin: 20px 0 0 0!important;
    }
    .titleH3_5{
        font-size: 24px!important;
    }
    .titleH4{
        font-size: 18px!important;
    }
    .adver4DivDiv{
        top: 16.5%;
        right: 9%;
    }
    /* .adver1DivImgDiv{
        width: 125px;
        height: 155px;
        margin-left: 30px;
        padding-top: 7%;
        border-radius: 12px;
        background: #AAEBEB;
        display: flex;
        text-align: center;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
    } */
    .adver1DivImgDiv {
        width: 200px;
        height: 210px;
        margin-left: -5px;
        /* padding-top: 8%; */
        border-radius: 12px;
        display: flex;
        text-align: center;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
    }
    .adver4DivTitleDiv {
        width: 145px;
        height: 125px;
        margin: auto;
        padding-top: 7%;
        border-radius: 12px;
        background: #FFC83C;
        display: flex;
        text-align: center;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
    }
    /* .adver1{
        position: absolute;
        top: 13%;
        right: 11%;
        z-index: 5;
        width: 170px;
    } */
    .adver1{
        position: absolute;
        top: -5%;
        left: 0%;
        width: 230px;
    }
    .adver2{
        top: 325px;
        left: 275px;
        width: 290px;
    }
    .adver3{
        top: 75px;
        right: 100px;
        width: 210px;
    }
    .adver4{
        position: absolute;
        top: 5%;
        right: 0%;
        width: 140px;
    }
    .right2Img{
        position: absolute;
        top: 80%;
        right: 3%;
        width: 140px;
    }
    .leftImg{
        position: absolute;
        z-index: 0;
        top: 110%;
        left: 2%;
        width: 200px;
    }
    .bottomImg{
        /* position: absolute;
        z-index: 0;
        bottom: -28%;
        left: 9%;
        width: 628px; */
        z-index: 0;
        bottom: 51%;
        left: 9%;
        width: 680px
    }
    .adver6{
        right: 78%;
    }
    .adver15{
        right: -25%;
    }
    .congratesSecOne{
        width: 56%;
        padding: 32px;
        margin-left: 0;
        border-radius: 15px;
    }
    .congratesSecOne p{
        font-size: 20px;
        line-height: 48px;
    }
    .congratesSecTwo{
        position: relative;
        top: -40px;
        width: 62%;
        padding: 32px;
        margin-left: 38%;
        border-radius: 15px;
    }
    .congratesSecTwo p{
        font-size: 20px;
        line-height: 48px;
    }
    .congratesSecThree{
        position: relative;
        top: -21px;
        left: -70px;
        width: 72%;
        padding: 48px;
        margin-left: 22%;
        margin-bottom: 48px;
        border-radius: 15px;
    }
    .congratesSecThree p {
        font-size: 20px;
        line-height: 48px;
    }

    /* 舊版本 */
    .container_customMargin{
        box-sizing: border-box;
        padding: 0 10vw !important;
    }
    /* .congratesSecOne{
        width: 60vw;
        margin: auto;
        box-sizing: border-box;
        margin-bottom: 1rem;
        padding: 12px;
    }

    .congratesSecOne p{
        margin: 0;
        color: #585858;
        font-size: 20px;
        font-weight: 500;
        font-family: 'Inter';
        font-style: normal;
        line-height: 48px;;
    }
    .congratesSecTwo{
        width: 60vw;
        margin: auto;
        box-sizing: border-box;
        margin-bottom: 1rem;
        padding: 12px;
    }

    .congratesSecTwo p{
        margin: 0;
        color: #585858;
        font-size: 20px;
        font-weight: 500;
        font-family: 'Inter';
        font-style: normal;
        line-height: 48px;;
    }

    .congratesSecThree{
        width: 60vw;
        margin: auto;
        color: #585858;
        box-sizing: border-box;
        padding: 12px;
        margin-bottom: 1rem;
    }
    .congratesSecThree p {
        margin: 0;
        color: #585858;
        font-size: 20px;
        font-weight: 500;
    
    }
    .congratesSecFour{
        width: 60vw;
        box-sizing: border-box;
        margin-bottom: 24px;
        padding: 12px;
        margin: 0 auto 1rem auto;
    }
    
    .congratesSecFour p{
        margin: 0;
        color: #1E8C96;
        font-size: 20px;
        font-weight: bold;
    } */
    .StepsTitle{
        color: #1E8C96;
        font-family: 'Inter';
        font-style: normal;
    
        border-bottom:4px solid #1E8C96;
        box-sizing: border-box;
        margin-bottom: 2rem;
        padding: 0;
        text-align: center;
    }
    .StepsTitle p{
    box-sizing: border-box;
    margin: 0 auto .3rem  auto;  
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    }
    .StepsCardCol{
        flex-direction: column;
    }
    .StepsCard, .StepsCardShareCert{
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 0;
        padding: 0;
        /* border: 3px solid #AAEBEB;
        box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.03);
        border-radius: 10px; */
    }
    /* .StepsCardflex{
        display: flex;
        justify-content: space-between;
    } */
    .StepsCardTitle p{
        color: #FA9600;
        font-style: normal;
        font-weight: bolder;
        font-size: 24px;
        line-height: 36px;
    }
    .StepsCardDescription p{
        color: #585858;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 143.52%;
        font-family: 'Inter';
        padding-right: .5rem;
    }
    .StepsCardImgSec{
        width: 312px;
        margin-bottom: 32px;
    }
    .StepsCardImg{
        width: 100%;
    }
    .StepTwoCardDescription, .StepThreeCardDescription{
        padding: 0 1rem 0 1rem;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 143.52%;
    }
    .StepTwoCardDescription p, .StepThreeCardDescription p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 143.52%;
        color: #585858;
    }
    .StepThreeDivContent img{
        width: 120px;
        height: 120px;
    }
    .StepTwoFLEX{
    display: flex;
    justify-content: space-between;
    }
    .StepTwoP{
        margin: 0;
    }
    
    .StepTwoCardDescription a{
        text-decoration: none;
        color: #1400FF;
    }
    .StepTwoCardDescriptionOne{
        color: #585858;
    }
    .howtoShareCertificateLink{
        text-decoration: none;
        color: #1400FF;
    }
    .StepThreeDescriptionList{
        box-sizing: border-box;
        border-radius: 6px;
        padding: .5rem 1rem .5rem 0;
        color: #585858;
        font-size: 1rem;
        font-weight: 500;
    }
    .StepThreeDescriptionList li{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 143.52%;
        color: #585858;
    }
    .StepThreeDescriptionListSpan{
        color: #FA9600;
    }
    .StepThreeCopyFLEX{
        width: 100%;
    }
    .StepThreeCopy{
        flex: 11;
        padding: 14px;
        /* margin: 0 0 12px 32px; */
    }
    .StepThreeCopy p{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 143.52%;
    color: #000000;
    margin: 0;
    }
    
    .CopySec{
        flex: 3;
        width: 100%;
    }
    
    .CopyButton{
        height: 75%;
        font-family: 'Inter';
        font-style: normal;
        font-size: 20px;
        line-height: 143.52%;
        /* background: #1E8C96; */
        border-radius: 3px;
        text-align: center;
        padding: 3px 20px;
    }
    .CopyButton:hover{
        border: 2px solid #FFFFFF;
        background-color: #1E8C96;
        color: #FFFFFF;

    }
    .lookSbSay{
        border: none;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 143.52%;
        background: #1E8C96;
        border-radius: 3px;
        text-align: center;
        color: white;
        padding: 3px 20px;
    }
    .lookSbSay:hover{
        box-shadow: 1px 1px 10px -1px #1E8C96;

    }
    .StepsThreeWidth{
        width: fit-content;
        background-color: #EBEBEB;
    }
    /* .StepThreeCopyAtt button{
        margin-right: 5%;
    } */
    
    
    .StepsCardONEflex{
        box-sizing: border-box;
        display: flex;
        padding: .5rem .5rem 1rem .5rem;
    }
    .lastSEC{
        /* display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; */
        height: 50px;
        text-align: center;
        padding:  2.5rem 0 0 0 ;
    }
    .lastSECSPAN{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 2vw;
        line-height: 35px;
        color: #585858;
        margin-top: 10px;
    }
    .SubmitSec{
        box-sizing: border-box;
        text-align: center;
        width: fit-content;
        display: inline-block;
    }
    .SubmitButton{
        box-sizing: border-box;
        background-color: #FA9600;
        padding: 6px;
        width: 28vw;
        color: white;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 30px;
        border: none;
        border-radius: 3px;
    }
    .SubmitButton:hover{
        box-shadow: 1px 1px 10px -1px #FA9600;
    }
    
    .whereisCertificatePhone{
        display: none;
    }
    .howtoShareCertificatePhone{
        opacity: 0;
        font-size: 0;
        line-height: 0;
        height: 0;
    }
    .WarningSec{
        font-family: 'Inter';
        font-style: normal;
        margin: 0;
        padding: 3rem;
        text-align: center;
    }
    .WarningSec p{
        font-size: 16px;
        line-height: 22px;
        color: #585858;
        margin: 0;
        padding: 0;
    }
    }
    @media screen and (max-width: 825px) {
    .adver4DivDiv{
        top: 18.5%;
        right: 9%;
    }
    .adver1DivImgDiv {
        width: 200px;
        height: 210px;
        margin-left: -5px;
    }
    .adver1{
        top: 0%;
        left: 0%;
    }
    .adver3{
        top: 295px;
        right: 295px;
    }
    .adver4{
        top: 8%;
    }
    .right2Img{
        top: 80%;
    }
    }
        @media screen and (max-width: 767px) {
        .bannerColPC{
            padding-top: 0rem;
        }
        .bannerColPhone{
            padding-top: 0;
        }
        .layoutComp{
            display: none;
        }
        .layoutPhone{
            display: block;
            margin: 25px auto 0;
            width: 100%;
        }
        .titleH1{
            font-size: 28px!important;
        }
        .titleH2, .titleH2_2{
            font-size: 20px!important;
        }
        .titleH3{
            font-size: 20px!important;
        }
        .titleH3_2{
            font-size: 20px!important;
            text-align: center;
            margin-bottom: 8px!important;
            color: #33658A!important;
        }
        .titleH3_3{
            width: fit-content;
            padding: 2px 5px;
            margin: 12px 0 0 0!important;
        }
        .titleH3_4{
            padding: 0 16px 16px;
        }
        .activeTitleDivPC{
            display:none;
        }
        .activeTitleDivPhone{
            display:block;
            margin-top: 60px!important;
        }
        .titleH3_5{
            font-size: 20px!important;
            margin-bottom: 5px!important;
        }
        .titleH4{
            font-size: 16px!important;
        }
        .twoMemberCard{
            width: 190px;
            margin-top: 0;
        }
        .bannerSec{
            position: initial;
            padding: 12px 0;
        }
        .adver1Div{
            width: 100%;
            text-align: right;
        }
        .adver4Div{
            width: 100%;
        }
        .adver4DivDiv {
            /* top: 30.5%; */
            top: 320px;
            right: 22%;
        }
        .adver1DivImgDiv{
            position: relative;
            top: 15px;
            right: -120px;
            /* width: 175px;
            height: 200px; */
            width: 220px;
            height: 230px;
            border-radius: 10px;
        }
        .adver4DivTitleDiv {
            width: 130px;
            height: 125px;
            padding-top: 10%;
            border-radius: 10px;
        }
        .adver1{
            position: absolute;
            /* bottom: 270px;
            left: -30px;
            width: 220px; */
            top: 75px;
            left: 130px;
            width: 185px;
        }
        .adver2{
            top: 275px;
            left: 165px;
            width: 235px;
        }
        .adver3{
            /* top: 120px;
            right: 10%; */
            top: 65px;
            right: 25px;
            width: 270px;
        }
        .adver4{
            top: 90px;
            right: 18%;
            width: 120px;
        }
        .right2Img{
            top: 80%;
            right: 18%;
            width: 120px;
        }
        .leftImg{
            /* position: absolute;
            z-index: 0;
            top: 329%;
            left: -13%;
            width: 180px; */
            position: absolute;
            z-index: 0;
            top: 150%;
            left: -15%;
            width: 180px;
        }
        .bottomImg{
            /* position: absolute;
            z-index: 0;
            bottom: -35%;
            left: 0%;
            width: 385px; */
            z-index: 0;
            bottom: 110%;
            left: -2%;
            width: 383px;
        }
        .adver5{
            display:none
        }
        .adver5Phone{
            display: block;
            object-fit: cover;
            object-position: top;
            width: 130px;
            height: 130px;
            position: absolute;
            top: 15px;
            right: -55px;
        }
        .adver6{
            object-fit: cover;
            object-position: top;
            width: 165px;
            height: 115px;
            position: absolute;
            top: -100px;
            left: -18px;
        }
        .adver15{
            object-fit: cover;
            object-position: top;
            width: 165px;
            height: 115px;
            position: absolute;
            top: 18px;
            left: 200px;
        }
        .congratesSecOne{
            width: 91%;
            padding: 3% 5%;
            margin-left: 0;
            border-radius: 10px;
        }
        .congratesSecOne p{
            font-size: 16px;
            line-height: 24px;
            margin: 6px 0;
        }
        .congratesSecTwo{
            position: relative;
            top: 15px;
            right: 115px;
            width: 91%;
            padding: 3% 5% 3% 26%;
            margin-left: 156px;
            border-radius: 10px;
        }
        .congratesSecTwo p{
            font-size: 16px;
            line-height: 24px;
            margin: 6px 0;
        }
        .congratesSecThree{
            position: relative;
            top: 30px;
            left: -88px;
            width: 90%;
            padding: 3% 5%;
            margin-left: 88px;
            margin-bottom: 60px;
            border-radius: 10px;
        }
        .congratesSecThree p {
            font-size: 16px;
            line-height: 24px;
            margin: 6px 0;
        }
        .container_customMargin{
            margin: 0;
            padding: 0 10px !important;
        }
        .bannerPC3{
            height: 100%;
            width: 100%;
            display: none;
        }
        .bannerPhone3{
            height: 100%;
            width: 100%;
            display: block;
        }
        .bannerBottomImg{
            margin: -18px 0px 0 85px;
        }
        /* .congratesSecOne{
            margin: auto;
            padding: 8px;
            box-sizing: border-box;
            margin-bottom: 1rem;
        }

        .congratesSecOne p{
            margin: 0;
            color: #585858;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 120.52%;
        }    
        .congratesSecTwo{
            margin: auto;
            box-sizing: border-box;
            margin-bottom: 1rem;
            padding: 8px;
        }

        .congratesSecTwo p{
            margin: 0;
            color: #585858;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 120.52%;
        }

        .congratesSecThree{
            color: #585858;
            box-sizing: border-box;
            padding: 8px;
            margin-bottom: 1rem;
        }
        .congratesSecThree p {
            margin: 0;
            color: #585858;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 120.52%;
        
        }
        .congratesSecFour{
            box-sizing: border-box;
            margin-bottom: 24px;
            padding: 8px;
            margin: 0 auto 1rem auto;
        }
        
        .congratesSecFour p{
            margin: 0;
            color: #1E8C96;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 120.52%;
        } */
        /* .StepsCardflex{
            display: flex;
            flex-direction: column;
        } */
        .StepsTitle{
            color: #1E8C96;
            border-bottom:4px solid #1E8C96;
            box-sizing: border-box;
            margin-bottom: 2rem;
            padding: 0;
            text-align: center;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 25px;
            line-height: 30px;
        }
        .StepsTitle p{
            box-sizing: border-box;
            margin: 0 auto .3rem  auto;  
            font-weight: 700;
            font-size: 25px;
            line-height: 30px;
        }
        .StepsCard, .StepsCardShareCert{
            width:100%;
            box-sizing: border-box;
            margin-bottom: 0.4rem;
            border: none;
            box-shadow: none;
            border-radius: none;
            padding: 0;
        }
        .StepsCardDescription p{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 120.52%;
            color: #585858;
        }
        .SubmitSec{
            box-sizing: border-box;
        }
        /* .SubmitButton{
            box-sizing: border-box;
            padding: .5rem;
            background-color: #FA9600;
            width: 14rem;
            color: white;
            font-size: 1rem;
            font-weight: 700;
            border: none;
            border-radius: 3px;
        } */
        .SubmitButton{
            margin-top: 5px;
            padding: 2px 10px;
            width: 30vw;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
        } 
        .SubmitButton:hover{
            box-shadow: 1px 1px 10px -1px #FA9600;
        }
        .grid{
            text-align: start;
        }
        .StepsCardONEflex{
            display: flex;
            flex-direction: column;
            padding: 0;
        }
        /* .StepsCardDescription{
            border-left:8px hsla(180, 62%, 79%, 0.301) solid ;
            margin-left: 1rem;
            padding-left: .3rem;
        } */
        .StepTwoCardDescription, .StepThreeCardDescription{
            padding: 0;
        }
        .StepTwoCardDescription p, .StepThreeCardDescription p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 120.52%;
            color: #585858;
        }
        .StepTwoCardDescriptionOne{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 300;
            font-size: 16px !important;
            line-height: 120.52%;
            color: #585858; 
        }
        .StepThreeDivContent img{
            width: 75px;
            height: 75px;
        }
        .StepTwoFLEX{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            display: flex;
            justify-content: space-between;
        }
        .StepTwoCardDescription a{
            text-decoration: none;
            color: #1400FF;
        }
        .StepThreeDescriptionList{
            box-sizing: border-box;
            background-color: #EBEBEB;
            border-radius: 6px;
            padding:  10px;
            color: #585858;
            width: fit-content;
        }
        .StepThreeDescriptionList li{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 120.52%;
            color: #585858;
        }
        .StepsCardImgSec{
            width: 265px;
            /* margin-bottom: 20px; */
            display: inline-block; 
        }
        .StepsCardImg{
            width: 100%;
        }
        .StepThreePStar{
            margin-top: 16px;
        }
        .WarningSec{
            margin: 0;
            padding: 1.5rem;
            text-align: center;
        }
        .WarningSec p{
            font-size: 12px;
            line-height: 18px;
            margin: 0;
            padding: 0;
        }
        .StepsCardTitle  {
            color: #FA9600;
            margin-bottom: 1rem;
        }
        .StepsCardTitle p{
            margin-bottom: 0;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 161.52%;
            color: #FA9600;
        }
        .whereisCertificatePhone{
            display: block;
        }
        .whereisCertificatePC{
            display: none;
        }
        .StepThreeCopyAttTextPC{
            margin-top: 30px;
            display: none;
        }
        .StepThreeCopyAttTextPhone{
            display: block;
        }
        .howtoShareCertificatePhone{
            opacity: 1;
            height: 100%;
        }
        .howtoShareCertificatePC{
            display: none;
        }
        .StepTwoP{
            margin: 0 0 0 20px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: #585858;
        }
        .howtoShareCertificateLink{
            text-decoration: none;
            color: #1400FF;
        }
        .StepThreeCopyFLEX{
            margin-bottom: 20px;
        }
        .StepThreeCopy{
            flex: 10;
            padding: 8px;
            /* margin: 0 0 12px; */
        }
        .StepThreeCopy p{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            /* line-height: 120.52%; */
            line-height: 18px;
            margin: 0;
            color: #000000;
        }
        .CopySec{
            width: 100%;
        }
        .CopyButton{
            height: 75%;
            font-style: normal;
            font-size: 12px;
            line-height: 120.52%;
            border-radius: 3px;
            text-align: center;
            /* padding: 8px 15px; */
            
        }
        .lookSbSay{
            border: none;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 120.52%;
            color: white;
            border-radius: 3px;
            background: #1E8C96;
            text-align: center;
            /* padding: 8px 15px; */
            
        }
        .lookSbSay:hover{
            box-shadow: 1px 1px 10px -1px #1E8C96;

        }
        .StepThreeCopyAtt p{
            margin-bottom: 0;
        }
        .StepThreeCopyAtt button {
            /* margin-right: 8%; */
            font-size: 12px;
            padding: 4px;
        }
        .lastSEC{
            height: 48px;
            text-align: center;
            margin-top: 16px;
            padding:0 ;
            display: flex;
            /* flex-direction: column; */
            justify-content: space-evenly;
        
        }
        .lastSECSPAN{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 25px;
            line-height: 30px;
            color: #585858;
            margin-top: 10px;
        
        }   
        /* }
        @media screen and (max-width:560px) { */
        .adver1DivImgDiv{
            right: -56px;
            margin: 0;
        }
        .adver1{
            left: 2px;
        }
        .adver4DivDiv {
            right: 8%;
        }
        .adver4{
            right: 0%;
        }
        .right2Img{
            right: -8%;
        }
        }
        /* @media screen and (max-width:540px) {
        .adver4DivDiv {
            left: 190px
        }
        .adver4{
            left: 225px;
        }
        } */
        @media screen and (max-width:490px) {
        .adver1DivImgDiv{
            left: 45px;
        }
        .adver1{
            left: 4px;
        }

        }
/* @media screen and (max-width:420px) {
    .adver1DivImgDiv{
        left: 45px;
    }
    .adver1{
        left: 25px;
    }
} */

/* 電腦版 */
/* @media only screen and (min-width: 967px){
} */
/* 平板 */
/* @media only screen and (min-width: 767px) and (max-width: 967px){
} */

/* 手機 */
/* @media only screen and (min-width: 0px) and (max-width: 767px){
} */



