/* *{
    outline: 1px solid red; 

} */
   

/* button:hover{
    box-shadow: 1px 1px 10px -1px #1e8c96;
    border-radius: 3px;
} */
div, span, p{
    font-size: 14px;
}
.container_fluid{
    padding: 0 !important;
}
.submitPageRow{
    width: 100%;
    margin: auto!important;
}
.formcolor{
    display: flex;
    flex-direction: row;
    /* justify-content: space-evenly; */
    justify-content: flex-start;
}
.form_div{
    padding: 0  !important;
}
.form_step1{
    padding: 0;
    position: relative;
    /* background-color: #55DDE0; */
    /* background-color: #55DDE088;
    border: 1px solid #a5d9dd;
    border-radius: 3px; */
    background-color: #FFFFFF00;
}
input{
    height: 28px;
    line-height: 28px!important;
}
.form_lableCardFLEX{
    width: 40%;
    max-width: 325px;
    margin: 20px auto 0;
}
.form_lableCardFLEXComputerPadding{
    padding:20px;
}
.form_lableCard{
    display: flex;
    margin-bottom: 30px;
}
.form_lableCard1{
    display: flex;
    margin-bottom: 0px;
}
.form_lableCard2{
    display: flex;
    margin-bottom: 20px;
}
.form_lableCard2:nth-child(1){
    margin-top: 0px;
    margin-bottom: 22px;
}
.form_lableCard3{
    display: flex;
    margin-bottom: 10px;
}
.form_lableCard4{
    display: flex;
    margin-bottom: 5px;
}
.form_lableSec{
    display: flex;
    flex-direction: row;
}
.form_lableCardPhone{
    display: flex;
    margin-bottom: 1rem;
}
.form_buttonPhoneDiv{
    width: 100%; 
    text-align: center;
}
.form_buttonPhone{
    /* margin-left: 2px;
    margin-right: 1.6rem;
    margin-top: 7px; */
    width: 99%; 
    margin-bottom: 1rem;
    font-size: 14px;
    background-color: #FA9600;
    border: none;
    color: white;
    box-shadow: 1px 1px 1px rgb(150 145 145);
    padding: 3px 8px;
    border-radius: 3px;
}
.form_buttonPhone:hover{
    box-shadow: 1px 1px 10px -1px #1e8c96;
}
.form_buttonPhone:disabled{
    background-color: #d2a561;
}
.form_lableCardSMS{
    display: flex;
    margin-bottom: 0;
}
.form_smsPadding{
    flex: 5.5;
}
.form_smsSec{
    flex: 9;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
}
.form_labelPhone{
    margin-left: 3px;
}
.form_verifySMSDiv{
    width: 100%;
}
.form_inputPhoneSec{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
} 
.form_inputPhoneDiv{
    display: flex;
}
.form_inputPhone{
    height: 28px;
    line-height: 28px!important;
    width: 154px;
}
.form_inputPhone:disabled{
    background-color: #ddd;
}
.form_inputSMS{
    /* width: 16.5rem; */
    width: 100%;
    /* letter-spacing: 0.8rem;
    padding-left: 0.8rem; */
}
.form_imgbox{
    height: 22px;
    width: 15px;
    margin-right: 1rem;
}
.form_img{
    width: 15px;
}
.lineSec{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.loginSec{
    width: 100%;
}
.loginLabel{
    padding: 10px;
    border: 1px solid #666;
    border-radius: 3px;
}
label{
    /* width: 9.375rem; */
    /* margin-right: 1rem; */
    color: #585858;
    font-size: 1rem;
    font-weight: bold;
    display: inline !important;
}
input{
    border: 1px solid #585858;
}
.form_inputName{
    width: 240px;
    flex: 6;
}
.form_inputName:disabled{
    background-color: #ddd;
}
.form_inputEmail{
    width: 240px;
    flex: 6;
}
.form_inputEmail:disabled{
    background-color: #ddd;
}
.form_lableAlign{
    flex: 3;
    width: 100px;
    line-height: 27px;
}
.form_PhonelableAlign{
    /* margin-right: 3rem;
    margin-bottom: 5px; */
    flex: 3;
    width: 111px;
}
/* .form_smsPadding{
    margin-right: 6.8rem;
} */
.form_countryCode{
    width: 70px;
    margin-right: 5px;
    margin-bottom: 5px;
    height: 28px;
}
.form_countryCode:disabled{
    background-color: #ddd;
    border-color: #333;
}
.LineEmailHint{
    display: block;
    font-size: 12px;
    color: #585858;
    /* font-weight: bold; */
    margin-top: .3rem;
    margin-bottom: 20px;
    margin-left: 1.9rem;
}
.BottomHint span{
    color: #F26419;
}
.banner_div{
    width: 100%;
    margin-bottom: 1rem;
    padding: 0 !important;
}
.banner_img{
    width: 100%;
}
.titileFlex{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 15px!important;
}
.titile{
    color: #1E8C96;
    font-weight: 700;
    /* font-size: 3rem; */
    text-align: center;
}
.titleH1{
    margin: 0 0 6px!important;
    font-family: 'Arial';
    font-style: normal;
    text-align: center;
    color: #1E8C96!important;
}
.titleH2{
    margin: 0!important;
    font-family: 'Roboto';
    font-style: normal;
    text-align: center;
    color: #FA9600!important;
}
.titleH2_2{
    margin: 0!important;
    font-family: 'Roboto';
    font-style: normal;
    text-align: center;
    color: #1E8C96!important;
}
.titleH3{
    margin: 0!important;
    color: #000!important;
}
.titleH4{
    margin: 0!important;
    color: #000!important;
}
.inline{
    display: inline-block;
    font-size: 18px;
}
.inlineTitle{
    display: inline-block;
    font-size: 18px;
    font-weight: 600!important;
}
.steps{
    color: #585858;
    font-weight: 500;
    font-size: 1rem;
    line-height: 120.52%;
    margin: 0 1rem;

}
.completedStep{
    color: #FA9600;
}
.UserUrlHint a, .UserUrlHint span, .UserUrlHint p, .UserUrlHint div {
    font-size: 12px;
}
.UserUrlHint a {
    color: #1400FF;
    text-decoration:none;
}
.UserUrlHint a:hover {
    color: #1400FF88;
    text-decoration:none;
}
.lineLabel{
    width: 100%;
    flex: 3;
    margin-right: 16px;
    line-height: 26px;;
}
.lineLoginBox{
    /* border: 2px solid #000; */
    margin-right: 16px;
}
.lineLoginImg {
    width: 145px;
    border-radius: 3px;
    margin-top: 2px;
}
.lineLoginImg:hover {
    cursor: pointer;
    box-shadow: 1px 1px 10px -1px #1e8c96;
}
.lineFriendImg {
    width: 260px;
    border-radius: 3px;
    margin-top: 2px;
}
.lineFriendImg:hover {
    cursor: pointer;
    box-shadow: 1px 1px 10px -1px #1e8c96;
}
.fbAddFansImg {
    width: 145px;
    border-radius: 3px;
    margin-top: 2px;
}
.fbAddFansImg:hover {
    cursor: pointer;
    box-shadow: 1px 1px 10px -1px #1e8c96;
}
/* .lineIconBox{
    width: 1.5rem;
    height: 25px;
    background-color: #06C755;
    box-shadow: 1px 1px 1px rgba(121, 115, 115, 0.658);
    border-radius: 3px;
    cursor: pointer;
}
.lineIconBox:hover{
    background-color: #02A52F  ;
    height: 25px;
}
.lineIconImg{
    width: 100%;
    cursor: pointer;
} */
.lineFriendIcon{
    width: 1.5rem;
    height: 21px;
}
.lineIconImg{
    width: 100%;
}
.lineFriendContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.lineFriendSec{
    display: flex;
    flex-direction: row;
    /* margin-right: 16px; */
}
.lineFriendBox{
    margin-right: 16px;
}
.lineFriendLabel{
    width: 33%;
    margin-right: 0;
    line-height: 27px;;
}
.lineFriendButtonClick{
    width: 100%;
    flex: 6;
    margin-top: 3px;
}
.lineFriendButtonDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    margin-top: 1px;
}
.lineFriendButton{
    width: 7rem;
    background-color: #06C755;
    display: flex;
    border: none;
    box-shadow: 1px 1px 1px rgba(104, 101, 101, 0.658);
    border-radius: 3px;
}
.hand{
    width:25px;
    margin-right: 5px;
}
/* iframe{
    width: 100%!important;
    height: 23px!important;
} */
.lineFriendButton:hover{
    background-color: #06C755  ;
    opacity: #000000 10%;
}
.lineFriendText{
    color: white;
    font-weight: bold;
}
.EndingHint{
    /* font-size: .6rem; */
    color:#585858; 
    text-align: center;
 
}
.EndingHint p{
    margin-bottom: 5px;
}
.BottomHint{
    /* font-size: .6rem; */
    color:#585858; 
    text-align: center;
}
.form_urlSec{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.form_urlLabel{
    display: flex;
    width: 21%;
    line-height: 27px;;
}
.form_inputUrl{
    width: 240px;
    flex: 6;
}
.form_submitSec{
    /* background-color: #FA9600; */
    width: 100%;
    margin: 1.5rem auto;
    text-align: center;
 }
 .form_submitButton{
    font-size: 16px;
    width: 100%;
    max-width: 20rem;
    background-color: #FA9600;
    border: none;
    color:white;
    box-shadow: 1px 1px 1px rgb(150, 145, 145);
    padding: .5rem;
    border-radius: 3px;
 }
 .form_submitButton:hover{
    box-shadow: 1px 1px 10px -1px #ffc107;
 }
 .UserUrlHint{
    /* font-size: .6rem; */
    text-align: center;
    margin-bottom: 1rem;
    color: #585858;
}
/* 錯誤訊息區塊 */
.RegxpErrorSec{
    width: 100%;
    /* margin-top: 20px; */
    text-align: center;
}
/* 錯誤訊息 */
.RegxpError{
    color: #F26419;
    margin: 0;
}
/* 錯誤訊息 */
.RegxpError:nth-last-child(1){
    margin-bottom: 45px;
}
/* 錯誤訊息連結 */
.RegxpErrorAnchor{
    color: #0000FF;
    text-decoration: none;
    cursor: pointer;
}
.RegxpErrorAnchor:hover{
    color: #0000FF88;
}
.StepThreeCopy{
    border: 1px solid #1E8C96;
    border-radius: 6px;
    padding: 5px;
    width: 100%;
    background-color: white;
    display: inline-block;
}
.StepThreeCopy p{
   font-family: 'Inter';
   font-style: normal;
   color: #585858;
   font-size: 14px;
   margin: 0;

}

.CopySec{
    width: 100%;
    text-align: end;
}
.CopyButton{
    border: none;
    font-family: 'Inter';
    font-style: normal;
    padding: 0.6rem 1.2rem;
    border-radius: 3px;
    background: #1E8C96;
    border-radius: 3px;
    text-align: center;
    color: white;

}
.CopyButton:hover{
    box-shadow: 1px 1px 10px -1px #1E8C96;
}
/* 手機改綁定訊息 */
.phoneUnlinkText{
    width: fit-content;
    padding: 6px 8px;
    margin: 8px auto 0;
    background-color: #96eff1;
    border: 1px solid #6cd3d5;
    border-radius: 6px;
}
.loginbutton{
    background-color: #1E8C96;
    border: none;
    width: 95%;
    color: white;
    padding: 0.3em;
    border-radius: 3px;
    font-size: 18px;
}
.loginbutton:hover{
    box-shadow: 1px 1px 10px -1px #1e8c96;
}
.loginBtn{
    width: 145px!important;
    margin: 5px;
    background-color: #1E8C96!important;
    box-shadow: 1px 1px 1px #0f4e53!important;
}
.loginBtn:hover{
    /* background-color: #1E8C96; */
    box-shadow: 1px 1px 10px -1px #259da8!important;
}
@media screen and (max-width: 1165px) {
    .form_lableCardFLEX{
        width: 50%;
    }
}
@media (max-width: 967px) {
    .form_lableCardFLEX{
        width: 60%;
    }
    input {
        height: 24px;
        line-height: 24px!important;
    }
    .form_lableSec{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .submitPageRow{
        width: 95%;
        margin: auto!important;
    }
    .form_inputName, .form_inputEmail, .form_inputUrl{
        width: 100%;
    }
    .form_smsPadding{
        flex: 1.1;
    }
    .lineLabel{
        flex: 4;
    }
    /* .lineFriendImg {
        width: 260px;
    } */
}
/* @media (max-width: 25.875rem){ */
@media (max-width: 768px) {
    form{
        padding: 0;
        margin:0;
        background-color: #55dee000;
    }
    .form_div{
        padding: 0 !important;
    }
    .titileFlex{
        display: inline-block;
    }
    .formcolor{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    label{
        display: block !important;
    }
    input{
        height: 24px;
        line-height: 24px!important;
    }
    .form_lableAlign{
        width: 100%;
    }
    .form_lableSec{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .form_lableCardFLEXComputerPadding{
        padding: 5px;
    }
    .form_inputName, .form_inputEmail, .form_inputPhone{
        width: 100%;
    }
    .form_countryCode {
        height: 28px;
    }
    .form_buttonPhoneDiv{
        width: 100%; 
        text-align: center;
    }
    .form_buttonPhone {
        width: 95%;
        font-size: 14px;
        padding: 3px 8px;
        /* margin-left: 2px;
        margin-right: 0.8rem;
        margin-top: 0px;
        margin-bottom: 5px; */
    }    
    .form_smsSec{
        flex-wrap: wrap;
    }
    .form_smsPadding{
        flex: .88;
    }
    .lineLabel{
        flex: 8;
        margin-right: 8px;
    }
    /* .lineLoginImg {
        width: 45%;
    }
    .lineFriendImg {
        width: 45%;
    } */
    /* .lineIconBox{
        width: 1.5rem;
        height: 25px;
        background-color: #06C755;
        box-shadow: 1px 1px 1px rgba(121, 115, 115, 0.658);
        
    }
    .lineIconBox:hover{
        background-color: #06C755  ;
        opacity: #000000 10%;
    } */
    .lineFriendSec{
        display: flex;
    }
    .lineIconImg{
        width: 100%;
    }
    .lineFriendButtonClick{
        width: 100%;
    }
    .lineFRIENDBUTTON{
        border: 1px solid blue !important;
        height: 25px;
    }
    .lineFriendButton{
        width: 7rem;
        background-color: #06C755;
        display: flex;
        border: none;
        box-shadow: 1px 1px 1px rgba(104, 101, 101, 0.658);
    }
    .lineFriendButton:hover{
        background-color: #06C755  ;
        opacity: #000000 10%;
    }
    .lineFriendIcon{
        width: 1.5rem;
        height: 21px;
    }
    .lineFriendText{
        color: white;
        font-weight: bold;
    }
    .form_submitSec{
       background-color: #FA9600;
       width: 13.563rem;
       margin-bottom: 1rem;
    }
    .form_submitButton{
       width: 100%;
       background-color: #FA9600;
       border: none;
       color:white;
       box-shadow: 1px 1px 1px rgb(150, 145, 145);
       padding: .5rem ;
       border-radius: 3px;
    }
    .EndingHint{
        /* font-size: .6rem; */
        color:#585858; 
     
    }
    .UserUrlHint{
      font-size: 1rem;
      margin: 0 1rem 1rem 1rem;
      color: #585858;
      
    }
    /* .UserUrlHintSpan{
         padding-left: 1rem;
    } */
    .UserUrlHintOne{
        display: block;
    }
    .BottomHint{
        text-align: center;
        width: 100%;
        font-size: 14px;
        color: #585858;
    }
    .buttoncolor{
        background-color: #FFFFFF00;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .form_inputUrl{
        width: 100%;
    }
    .lineLoginBox, .lineLabel, .lineFriendSec, .lineFriendLabel{
        margin-right: 8px;
        font-size: 14px;
    }
    .lineFriendSec{
        margin: 0;
    }

}
@media screen and (max-width: 676px ) {
    .form_lableCardFLEX{
        width: 80%;
    }
}
@media screen and (max-width: 525px ) {
    .form_lableCardFLEX{
        width: 100%;
    }
}