html, body {
    height:100%;
    width:100%;
    margin:0;
}
.gbLayout{
    background-color: #FFF;
}
.container_fluid{
    padding: 0 !important;
    margin: 0 !important;
}
.gbTop{
    margin: 18px 0 0;
    padding: 12px;
    background-color: #FA9600;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.gbTopCol1{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.gbTopCol2{
    display: flex;
    flex-direction: column;
    align-items: left;
}
.gbTopCol2TitleGroupDiv{
    margin: 8px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.gbMain{
    /* height: 91%; */
    /* min-height: 1832px; */
    background-color: #dadada;
    padding: 12px;
}
.gbContainerRow{
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
}
.gbRowCourse {
    /* margin: 16px 8px!important; */
    margin: 0 auto!important;
}
.gbColCourse02Title, .gbColCourse212Title{
    margin:0 8px 0 16px;
    padding: 0;
    display: flex;
    flex: 1;
}
.gbCollapse{
    margin: 0 0 0 8px;
}
.gbColCourse02Content, .gbColCourse212Content{
    /* margin:0 16px 0 0; */
    margin:0 auto; 
    padding: 0;
    flex: 20;
}
.gbRowCourseImg{
    background-color: #FFF;
    display: flex;
    margin: 0 0 8px;
    border: 2px solid #EBEBEB;
}
.gbRowCourseImg:last-child{
    margin: 8px 0 0;
}
.gbColCourseImg{
    background-color: #FFF;
    padding: 8px;
    width: 100%;
    display: flex;
    /* margin: 0 0 8px; */
    align-items: center;
}
.gbColCourseDescription{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
}
.gbColCourseTotal{
    align-items: flex-end;
}
.gbImgCoursePC{
    margin:8px;
    width: 385px;
    height: 217px;
    display: block;
}
.gbImgCoursePCIcon{
    width: 100px;
    height: 100px;
    position: relative;
    top: -69px;
    left: -102px;
    display: block;
}
.gbImgCoursePhone{
    cursor: pointer;
    margin:8px;
    width: 250px;
    /* display: none; */
}
.gbImgCoursePhoneCC02{
    margin:8px;
    width: 275px;
}
.gbPanel{
    padding: 18px;
    font-size: 16px;
    color: #585858;
    background-color: #A5D8EB;
    display: none;
    overflow: hidden;
}
.gbSpanPhone{
    display: none;
}
.gbRowNote{
    background-color: #FFF;
    margin: 0!important;
    padding:  10px 20px 10px 40px;
}
.gbUlNote{
    margin: 0;
}
.gbLiNote{
    margin: 0 0 4px 0;
    font-size: 16px;
    color: #585858;
}
.gbRowAntButton{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.gbAntButton{
    background-color: #FA9600;
    color: #FFF;
    border: none;
    width: 180px;
    height: 50px;
    margin: 10px 0 20px;
    border-radius: 3px;
}
.gbAntButton:hover{
    background-color: #FFC83C;
}
.gbAntButtonConfirm, .gbAntButtonReturnCreateGroup{
    margin: 12px;
}
.gbAntButtonConfirm{
    margin: 12px 0 8px;
}
.gbAntButtonConfirm:disabled{
    background-color: #b5b5b5;
}
.gbAntButtonConfirm:disabled:hover{
    background-color: #bbb;
}
.gbAntButtonConfirm span, .gbAntButtonReturnCreateGroup span{
    padding-left: 0!important;
}
.gbAntButtonReturnCreateGroup{
    background-color: #1E8C96;
    margin: 12px;
}
.gbAntButtonReturnCreateGroup:hover{
    background-color: #2CAAB5;
}
.gbAntButton span{
    color: #FFF;
    padding-left: 16px;
    font-size: 20px;
    font-weight: 600;
}
.gbDivAntButtonCourseContent{
    margin: 12px 0 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.gbAntButtonCourseContent{
    background-color: #1E8C96;
    border: none;
    font-size: 14px;
    padding: 4px 8px;
    height: 32px;
    /* margin: 40px 0 0; */
    border-radius: 3px;
}
.gbAntButtonCourseContent:hover{
    background-color: #2caab5;
}
.gbAntButtonCourseContent span{
    color: #FFF;
    font-size: 14px;
}
.gbBottomRow0{
    background-color: #FFF;
    margin: -1px 0 0 0;
    width: 100%;
    height: 80px;
}
.gbBottomRow0Col{
    background-color: #AAEBEB;
    border-radius: 0 0 50% 50%;
}
.gbBottomRow1{
    background-color: #FFF;
    /* width: 100%; */
    width: fit-content;
    /* height: 150px; */
    margin: 8px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.gbBottomRow1:hover h4{
    text-decoration: underline;
}
.gbBottomRow2{
    /* position: relative;
    top: -17px;
    background-color: #AAEBEB;
    border-radius: 0 0 50% 50%; */
    background-color: #FFF;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -1px;
    /* margin-bottom: -40px; */
}
.gbContentToolAddress, .gbContentBox{
    padding: 12px;
}
.gbContentSecToolAddress{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.gbContentBox{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.gbContentBoxTitle{
    padding: 8px 8px 0;
    width: 100%;
}
.gbContentSecTapPay{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.gbContentSecDivToolAddress{
    width: 100%;
    display: flex;
}
.gbContentSecDivDivToolAddress{
    width: 100%;
}
.gbUnderlineInputDiv{
    position: relative;
    margin: 8px;
  }
.gbUnderlineInput{
    border: none;
    border-bottom: 2px solid #000; /* 底線顏色可以自行更改，這裡使用黑色 */
    font-size: 16px;
    outline: none; /* 去除默認的focus時的外框 */
    padding: 5px;
    width: 6rem;
}
.gbForm{
    background-color: #FFF;
    margin: 10px 0 0 20px;
}
.gbFormTapPay{
    background-color: #FFF;
    margin: 0;
}
.gbFormRow, .gbFormRow div{
    display: flex;
    align-items: center;
}
.gbFormRow label, .gbFormRow input, .gbFormRow select, .gbFormRow span, .gbFormRow textarea{
    font-size: 16px!important;
    height: 32px;
    display: flex;
    align-items: center;
}
.gbFormRow label{
    height: fit-content;
}
.gbFormRow textarea{
    height: 6rem;
}
.gbFormRow iframe{
    width: fit-content;
    height: 18px!important;
}
.gbFormRowOrderNote div{
    align-items: flex-start;
}
/* .gbFormRowCardNumber{
    margin-top: 12px;
} */
.gbInputTextCardNumber{
    width: 20rem;
}
.gbInputTextCardExpirationDate{
    width: 8rem;
}
.gbInputTextCardCcv{
    width: 6rem;
}
.gbInputTextPostcode{
    width: 6rem;
}
.gbInputTextCity{
    width: 10rem;
}
.gbInputTextAddress{
    width: 32rem;
}
.gbCountryToState{
    width: 12rem!important;
    width: fit-content!important;
}
.gbCollapseBillingForm{
    width: 100%;
}
.gbPanelBillingForm{
    width: 100%;
}
.gbInputTextOrderNote{
    width: 100rem;
}

.bannerColPC{
    padding-top: 1rem;
}
.bannerColPhone{
    padding-top: 0;
}
.bannerTitleImgDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.bannerImgDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.twoMemberCard{
    width: 500px;
    margin-top: 25px;
    z-index: 5;
}
.gbAdd2cartPC{
    width: 400px;
    margin-top: 25px;
    z-index: 5;
}
.bannerBottomImgDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.bannerBottomImg{
    width: 340px;
    margin-top: 25px;
    margin-left: -70px;
}
.bannerSec{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    width: fit-content;
    /* padding: 4rem 0; */
    padding: 20px 0 40px;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
}
.bannerPC3{
    width: 100%;
}
.bannerPhone3{
    width: 100%;
    display: none;
}
.adver1Div{
    z-index: 0;
    width: 18%;
}
.adver4Div{
    width: 20%;
}
.adver4DivDiv{
    position: absolute;
    /* top: 14.5%; */
    top: 18.5%;
    right: 13%;
    z-index: 0;
    width: 180px;
}
.adver1DivImgDiv{
    /* width: 195px;
    height: 215px; */
    width: 270px;
    height: 300px;
    margin-left: 40px;
    padding-top: 10%;
    border-radius: 15px;
    /* background: #AAEBEB; */
    display: flex;
    text-align: center;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}
.adver4DivTitleDiv {
    width: 185px;
    height: 180px;
    margin: auto;
    padding-top: 10%;
    border-radius: 15px;
    background: #FFC83C;
    display: flex;
    text-align: center;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}
.layoutComp{
    z-index: 10;
    width: 80%;
    background-color: #FFFFFF00;
    align-items: center;
    /* margin-right: 36px; */
    margin-left: 36px;
}
.layoutPhone{
    z-index: 0;
    width: 50%;
    background-color: #fff;
    align-items: center;
    display: none;
}
.titleH1, .titleH1_2{
    position: relative;
    margin: 8px auto!important;
    font-size: 36px!important;
    font-family: 'Arial';
    font-style: normal;
    text-align: center;
    color: #FA9600!important;
    z-index: 10;
    width: fit-content;
}
.titleH1_2{
    font-size: 42px!important;
    color: #1E8C96!important;
}
.titleH2{
    position: relative;
    margin: 0 auto!important;
    font-family: 'Roboto';
    font-style: normal;
    text-align: center;
    color: #FA9600!important;
    z-index: 10;
    width: fit-content;
}
.titleH2_2{
    position: relative;
    margin: 0 auto!important;
    font-family: 'Roboto';
    font-style: normal;
    text-align: center;
    color: #1E8C96!important;
    z-index: 10;
    width: fit-content;
}
.titleH3{
    position: relative;
    margin: 0 auto!important;
    color: #000!important;
    text-align: center;
    z-index: 10;
    width: fit-content;
}
.titleH3_2{
    position: relative;
    font-size: 24px!important;
    margin: 0 0 0 8px!important;
    color: #1E8C96!important;
    text-align: left;
    z-index: 10;
    width: fit-content;
}
.titleH3_3{
    font-size:28px!important;
    text-orientation: upright;
    writing-mode: vertical-rl;
    position: relative;
    /* margin: 24px 0 20px 160px!important; */
    color: #FFF!important;
    text-align: center;
    z-index: 10;
    background-color: #FFC83C;
    /* width: 275px; */
    border-radius: 3px;
    margin: 0!important;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.titleH3_4{
    font-size:28px!important;
    text-orientation: upright;
    writing-mode: vertical-rl;
    position: relative;
    /* margin: 24px 0 20px 160px!important; */
    color: #FFF!important;
    text-align: center;
    z-index: 10;
    background-color: #33658A;
    /* width: 275px; */
    border-radius: 3px;
    margin: 0!important;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.activeTitleDivPC{
    display:block;
}
.activeTitleDivPhone{
    display:none;      
}
.titleH3_5, .titleH3_5Span, .titleH3_5Tool, .titleH3_5Total, .titleH3_5Total span{
    position: relative;
    font-size: 24px!important;
    text-align: center;
    color: #585858!important;
    z-index: 10;
    margin: 0!important;
    padding: 0!important;
    width: fit-content;
}
.titleH3_5Span{
    text-decoration: underline;
    color: #1E8C96;
    cursor: pointer;
}
.titleH3_5Tool{
    font-size: 22px!important;
    text-align: left;
}
.titleH3_5Total{
    font-weight: 400!important;
    align-items: flex-end;
}
.titleH3_6{
    position: relative;
    margin:auto!important;
    font-size: 32px!important;
    color: #333333!important;
    text-align: center;
    z-index: 0;
    /* background-color: #FFFFFF88; */
    width: 100%;
    border-radius: 3px;
    padding: 8px;
}
.titleH3_7, .titleH3_7_2, .titleH3_7_3, .titleH3_7_4{
    position: relative;
    font-size: 20px!important;
    margin: 0 0 0 8px!important;
    color: #FFF!important;
    text-align: left;
    z-index: 10;
    width: fit-content;
}
.titleH3_7{
    margin: 0 0 8px 8px!important;
    font-weight: 400!important;
}
.titleH3_7_3, .titleH3_7_4{
    margin: 0!important;
    padding: 0!important;
}
.titleH3_7_3{
    font-size: 28px!important;
    font-weight: 600!important;
}
.titleH3_7_4{
    font-size: 20px!important;
    font-weight: 400!important;
}
.gbTitleNoGroup{
    color: #F26419!important;
    background-color: #FFF;
    padding: 2px 8px!important;
}
.gbTitleGroup{
    color: #1E8C96!important;
    background-color: #FFF;
    padding: 2px 8px!important;
}
.titleH4, .titleH4_2{
    position: relative;
    font-size: 24px!important;
    margin: 0 0 0 8px!important;
    color: #585858!important;
    text-align: center;
    z-index: 10;
    /* background-color: #FFFFFF88; */
    width: fit-content;
}
.titleH4{
    text-align: left!important;
}
.titleH4_2{
    text-decoration: line-through;
}
.titleH4_3{
    cursor: pointer;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;    
    position: relative;
    margin: 0 0 0 8px!important;
    color: #585858!important;
    text-align: center;
    text-decoration: underline;
    z-index: 10;
    /* background-color: #FFFFFF88; */
    width: fit-content;
}
.titleH4_3:hover{
    text-decoration: underline;
    cursor: pointer;
}
.titleH4_4, .titleH4_5, .titleH4_6, .titleH4_7{
    margin: 8px!important;
    font-size: 18px!important;
}
.titleH4_5{
    font-size: 22px!important;
    text-align: center;
}
.titleH4_6, .titleH4_7{
    font-size: 24px!important;
}
.titleH4_5, .titleH4_6{
    padding: 0!important;
}
.titleH4_6, .titleH4_7{
    font-size: 20px!important;
    color: #585858!important;
    display: flex;
    align-items: center;
}
.titleH4_6{
    width: fit-content;
    margin: 8px 4px!important;
}
.titleH4_7{
    text-decoration: underline;
    /* margin: 0 0 12px!important; */
    margin: 4px 12px 4px 0!important;
    padding: 0!important;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
}
.titleH4_7:hover{
    color: #888!important;
}
.titleH5{
    margin: 0.5rem!important;
    font-size: 16px!important;
    font-weight: 600!important;
    color: #1E8C96!important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* background-color: #FFFFFF88; */
}
.titleH5 a{
    color: #333!important;
}
.titleH5 a:hover{
    text-decoration: underline;
}
.lineBindFriendDiv{
    display: flex;
}
.inline{
    display: inline-block;
    font-size: 18px;
}
.inlineTitle{
    display: inline-block;
    font-size: 18px;
    font-weight: 600!important;
}
.memberTableCol{
    position: relative;
    z-index: 1;
    /* background-color: #FFFFFF00; */
}
.grantMembershipSec{
    position: relative;
    z-index: 1;
    /* background-color: #FFFFFF88; */
}
.orangeLineDiv{
    width: 100%;
}
.orangeLine{
    display: block;
    width: 77%;
    margin: auto;
    position: relative;
    z-index: 5;
    margin-left: 10%;
}
/* .congratesSecONE{
    width: 60vw;
    box-sizing: border-box;
    margin-bottom: 1rem;
    padding: 0 .5rem 1rem .5rem;
    margin: auto;
}
.congratesSecONE P{
    margin: 0;
    color: #585858;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 143.52%;
} */
.congratesSecOne{
    position: relative;
    width: 38%;
    display: flex;
    flex-direction: column;
    /* width: 60vw; */
    box-sizing: border-box;
    padding: 48px;
    margin-left: 15%;
    background-color: #FFC83C55;
    border-radius: 15px;
}
.congratesSecOne p{
    margin: 0;
    color: #585858;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    /* line-height: 143.52%; */
    line-height: 48px;
}
.congratesSecTwo{
    position: relative;
    top: -100px;
    width: 38%;
    display: flex;
    flex-direction: column;
    /* width: 60vw; */
    box-sizing: border-box;
    padding: 48px;
    /* margin: auto; */
    margin-left: 50%;
    background-color: #AAEBEB55;
    border-radius: 15px;
}
.congratesSecTwo p{
    margin: 0;
    color: #585858;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 48px;
}
.congratesSecThree{
    position: relative;
    top: -80px;
    width: 50%;
    display: flex;
    flex-direction: column;
    /* width: 60vw; */
    box-sizing: border-box;
    padding: 48px;
    /* margin: auto; */
    margin-left: 22%;
    margin-bottom: 48px;
    background-color: #FA960033;
    border-radius: 15px;
}
.congratesSecThree p {
    margin: 0;
    color: #585858;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 48px;
}
.congratesSecFour{
    width: 400px;
    display: flex;
    flex-direction: column;
    /* width: 60vw; */
    box-sizing: border-box;
    margin-bottom: 24px;
    padding: 48px;
    margin: 0 auto 1rem auto;
}
.congratesSecFour p{
    margin: 0;
    color: #1E8C96;
    font-size: 20px;
    font-weight: bold;
}
.courseDiv{
    display: flex;
    margin-bottom: 2rem;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 2rem;
}
.courseDiv div{
    width: 100%;
    text-align: center;
}
.courseDiv div img{
    width: 90%;
}
.stepCircle{
    background-color: #1E8C96;
    border-radius: 50%;
    width: 42px;
    height: 42px;
    text-align: center;
}
.stepCircle p {
    width: 42px;
    height: 42px;
    font-family: 'Inter';
    font-style: normal;
   font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: white;
}

/* 舊版型 */
.lesson {
    --x: 0;
    --y: 0;
    --angle: 0deg;
    transform: rotate3d(var(--x), var(--y), 0, var(--angle));
}
.container_customMargin{
    box-sizing: border-box;
    padding: 0 10vw !important;
    flex: initial;
    width: 100%;
    max-width: 1536px;
}
.Stepsflex1{
    display: block;
}
.StepsTitle{
    color: #1E8C96;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    border-bottom:4px solid #1E8C96;
    box-sizing: border-box;
    margin-bottom: 2rem;
    padding: 0;
    text-align: center;
}
.StepsTitle p{
  box-sizing: border-box;
  margin: 0 auto .3rem  auto;  
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
}
.StepsCardCol{
    display: flex;
}
.StepsCard, .StepsCardShareCert{
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 1rem;
    padding: 1rem;
    /* border: 3px solid #AAEBEB;
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.03); */
    border-radius: 10px;
    margin-right: 10px;
}
.StepsCardShareCert{
    margin-bottom: 0;
    padding: 0;
}
/* .StepsCardflex{ */
    /* display: flex;
    justify-content: space-between; */
/* } */
.StepsCardTitle p{
    color: #FA9600;
    font-style: normal;
    font-weight: bolder;
    font-size: 30px;
    line-height: 36px;
}
.StepsCardDescription p{
    color: #585858;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 143.52%;
    font-family: 'Inter';
    padding-right: .5rem;
    
}
.StepsCardImgSec{
   width: 350px;
}
.StepsCardImg{
    width: 100%;
}
.StepTwoCardDescription, .StepThreeCardDescription{
    padding: 0 1rem 1rem 1rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 143.52%;
}
.StepTwoCardDescription p, .StepThreeCardDescription p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    /* line-height: 143.52%; */
    line-height: 32px;
    color: #585858;
    margin-bottom: 12px;
}
.StepTwoFLEX{
  display: flex;
  justify-content: space-between;
}
/* 貼上以下文字 */
.StepThreeDiv p{
    margin-bottom: 12px;
}
.StepThreeFLEX1{
    display: flex;
    margin-bottom: 1rem;
}
.StepThreePStar{
    flex:1;
    margin-top: 7px;
}
.StepThreeFLEX2{
    flex:29;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
}
.StepThreeFLEX2 p{
    /* margin-bottom: 12px; */
    display: flex;
    height: 48px;
    align-items: center;
}
.StepThreeDivContent, .StepThreeDivOr{
    flex: 1;
    margin-right: 100px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.StepThreeDivContent img{
    width: 165px;
    height: 165px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
}
.StepThreeDivOr p{
    color: #FFC83C;
    font-size: 18px;
}
.StepThreeFLEX p{
    display: flex;
}
.StepTwoP{
    margin: 0;
}

.StepTwoCardDescription a{
    text-decoration: none;
    color: #1400FF;
}
.StepTwoCardDescriptionOne{
    color: #585858;
    font-weight: 600!important;
    font-size: 20px;
}
.howtoShareCertificateLink{
    text-decoration: none;
    color: #1400FF;
}
.howtoShareCertificateLink:hover{
    color: #1400FF88;
}
.StepThreeDescriptionList{
    box-sizing: border-box;
    border-radius: 6px;
    padding: .5rem 1rem .5rem 0;
    color: #585858;
    font-size: 1rem;
    font-weight: 500;
}
.StepThreeDescriptionList li{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 143.52%;
    color: #585858;
}
.StepThreeDescriptionListSpan{
    color: #FA9600;
}
.StepThreeCopyFLEX{
    display: flex;
    width: 100%;
    border-radius: 3px;
    margin-bottom: 32px;
}
.StepThreeCopy{
    flex: 14;
    border: 1px solid #EBEBEB;
    border-radius: 3px;
    padding: 12px;
    /* margin-bottom: 12px; */
    background-color: #EFEFEF;
}
.StepThreeCopy p{
   font-family: 'Inter';
   font-style: normal;
   font-weight: 500;
   font-size: 20px;
   line-height: 143.52%;
   color: #000000;
   margin: 0;
}

.CopySec{
    flex: 2;
    display: flex;
    align-items: center;
    text-align: center;
    background-color: #EFEFEF;
    /* margin-bottom: 12px; */
    padding: 12px;
}

.CopyButton{
    border: 1px solid #1E8C96;
    background-color: #FFFFFF00;
    color: #1E8C96;
    height: 85%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 143.52%;
    /* background: #1E8C96; */
    border-radius: 3px;
    text-align: center;
    /* color: white; */
    /* padding: 2px 5px; */
    width: 100%;
}
.CopyButton:hover{
    /* box-shadow: 1px 1px 10px -1px #1E8C96; */
    border: 2px solid #FFFFFF;
    background-color: #1E8C96;
    color: #FFFFFF;
}
.lookSbSay{
    border: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 143.52%;
    background: #1E8C96;
    border-radius: 3px;
    text-align: center;
    color: white;
    /* padding: 2px 5px; */
    width: 100%;
    height: 100%;

}
.lookSbSay:hover{
    box-shadow: 1px 1px 10px -1px #1E8C96;

}
.StepsThreeWidth{
    width: fit-content;
    background-color: #EBEBEB;
}
/* 分享該注意甚麼? */
.StepThreeCopyAtt{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.StepThreeCopyAtt p{
    /* margin-right: 12px; */
    margin-bottom: 5px;
    color:#1400FF;
    flex: 1;
    text-align: right;
}
.StepThreeCopyAtt button{
    flex: 1;
    /* margin-right: 23%; */
    font-size: 20px;
    padding: 0.5rem 5rem;
    width: fit-content;
}
.StepsCardONEflex{
    box-sizing: border-box;
    display: flex;
    padding: .5rem .5rem 1rem .5rem;
}
.lastSEC{
    /* height: 200px;
    text-align: end;
    padding:  6rem 0 0 0 ; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
    height: 130px;
}
.lastSECSPAN{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 2vw;
    line-height: 2.5rem;
    color: #585858;

}
.SubmitSec{
    box-sizing: border-box;
    text-align: center;
    width: fit-content;
    display: inline-block;
}
.SubmitButton{
    box-sizing: border-box;
    /* padding: 0 1vw; */
    padding: 0;
    /* margin: 10px; */
    background-color: #FA9600;
    width: 20vw;
    color: white;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    /* font-size: 48px; */
    line-height: 48px;
    border: none;
    border-radius: 3px;
}
.SubmitButton:hover{
    box-shadow: 1px 1px 10px -1px #FA9600;
}

.whereisCertificatePhone{
    display: none;
}
.StepThreeCopyAttTextPC{
    line-height: 32px!important;
    margin-top: 30px;
}
.StepThreeCopyAttTextPhone{
    display: none;
}
.howtoShareCertificatePC{
    list-style-type: none;
    line-height: 32px!important;
}
.howtoShareCertificatePhone{
    list-style-type: none;
    opacity: 0;
    font-size: 0;
    line-height: 0;
    height: 0;
}
.WarningSec{
    margin: 0;
    padding: 1.5rem 0 3rem;
    text-align: center;
}
.WarningSec p{
    font-family: 'Inter';
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    color: #585858;
    margin: 0;
    padding: 0;
 
}
.h_iframe_pc {
    display: block;
    height: 100%;
    width:100%;
    text-align: center!important;
}
.h_iframe_phone {
    display: none;
}
.h_iframe_pc iframe {
    width: 60%;
    height: 25vw;
    border-radius: 3px;
    margin-top: 35px;
}

/*
** TapPay3dVeriPopup 
*/
/* 按鈕右邊界 */
.loginButton{
    margin-right: 1em;
}
/* 給AutoPopup使用，提示標題樣式 */
.modalTitle{
    width: 100%;
    margin-left: 1.2em;
    text-align: center;
    font-size: 1.2em;
    color: #FA9600;
}
/* Popup 按鈕 - 登入/註冊文字 */
.loginText {
    color: #666;
}
.loginText:active {
    color: #eee;
}
/* Popup 整個內容區塊 */
.modal {
    background-color: white;
    border-radius: 3px;
}
/* Popup Header層 */
.modalHeader{
    /* padding: 8px; */
    border-width: 0;
    border: none !important;
    width: 100%;
    border-radius: 3px;
}
.modalClose{
    position: absolute;
    font-weight: 700;
    color: white;
    font-size: 20px;
    top: -25px;
    cursor: pointer;
}
.PopupBanner{
    border-radius: 3px;
    width: 100%;
    height: 100%;

}
.PopupBanner img{
    border-radius: 3px;
    width: 100%;
    height: 100%;
}
.modalBody{
    background-color: white;
    padding: .6rem .6rem 1rem!important;
}
.modalIframe{
    width: 100%;
    height: 350px;
}
/*** END TapPay3dVeriPopup ***/

    /* @media screen and (max-width: 1200px) {
        .titleH5{
            margin-right: 10px;
        }
    } */
    @media screen and (max-width: 967px) {
    .gbContentSecTapPay{
        width: 100%;
    }
    .gbTopCol2TitleGroupDiv{
        margin: 4px 0;
    }
    .gbMain{
        /* background-color: #FFF; */
        padding: 12px 0;
        height: 59%;
        /* min-height: 1170px; */
    }
    .gbSpanPhone{
        display: inline;
    }
    .gbRowCourseImg:last-child{
        margin: 4px 0 0;
    }
    .gbColCourseImg{
        /* margin: 0 0 4px; */
    }
    .gbPanel{
        padding: 8px;
        font-size: 12px;
    }
    .gbRowNote{
        margin: 0px 16px 0 36px;
    }
    .gbLiNote{
        font-size: 12px;
    }   
    .gbContentToolAddress{
        padding: 4px;
    }
    .gbAntButton{
        width: 120px;
        height: 32px;
        margin: 24px 0 0;
        border-radius: 3px;
    }
    .gbAntButton span{
        padding-left: 12px;
        font-size: 14px;
    } 
    .gbAntButtonConfirm, .gbAntButtonReturnCreateGroup{
        margin: 12px;
    }
    .gbAntButtonConfirm{
        margin: 12px 0 4px;
    }
    /* .gbBottomRow1{
        height: 90px;
    } */
    .gbInputTextAddress{
        width: 18rem;
    }
    .gbForm{
        margin: 4px 0 0 8px;
    }
    .gbFormRow{
        margin-bottom: 6px;
    }
    .gbFormRow label, .gbFormRow input, .gbFormRow select, .gbFormRow span, .gbFormRow textarea{
        font-size: 12px!important;
    }
    .gbFormRow iframe{
        height: 16px!important;
    }
    .bannerColPC{
        padding-top: 1rem;
    }
    .bannerColPhone{
        padding-top: 0;
    }
    .h_iframe_pc {
        display: none;
    }
    .h_iframe_phone {
        display: block;
        height: 100%;
        width:100%;
        text-align: center;
    }
    .h_iframe_phone iframe {
        width: 100%;
        height: 41vw;
        border-radius: 3px;
        margin-top: 35px;
    }
    .twoMemberCard{
        width: 500px;
        margin-top: 15px;
    }
    .bannerBottomImgDiv{
        display: flex;
        flex-direction: column;
    }
    .bannerBottomImg{
        margin: -25px 0px 0 60px
    }
    .bannerSec{
        padding: 35px 0 16px;
    }
    .layoutComp{
        margin-right: 40px;
        /* margin-right: -5px; */
    }
    .titleH1{
        font-size: 28px!important;
    }
    .titleH1_2{
        font-size: 32px!important;
    }
    .titleH2, .titleH2_2{
        font-size: 24px!important;
    }
    .titleH3{
        font-size: 20px!important;
    }
    .titleH3_2{
        font-size: 18px!important;
        /* margin-bottom: 10px!important; */
    }
    .titleH3_3{
        font-size: 18px!important;
        /* margin: 20px 0 0 0!important; */
    }
    .titleH3_4{
        font-size: 18px!important;
        /* margin: 20px 0 0 0!important; */
    }
    .titleH3_5, .titleH3_5Span, .titleH3_5Tool, .titleH3_5Total, .titleH3_5Total span{
        font-size: 18px!important;
    }
    .titleH3_5Tool{
        font-size: 15px!important;
        text-shadow: 1px 1px 1px #d5d5d5;
    }
    .titleH3_7, .titleH3_7_2, .titleH3_7_3, .titleH3_7_4{
        font-size: 13px!important;
    }
    .titleH3_7_3{
        font-size: 18px!important;
    }
    .titleH3_7_4{
        font-size: 12px!important;
    }
    /* .titleH4, .titleH4_2, .titleH4_3{
        font-size: 12px!important;
    } */
    .titleH4, .titleH4_2, .titleH4_3, .titleH4_4 {
        font-size: 16px!important;
    }
    .titleH4_4 {
        font-size: 12px!important;
    }
    .titleH4_5{
        font-size: 18px!important;
    }
    .titleH4_6, .titleH4_7{
        font-size: 14px!important;
    }
    .titleH4_4, .titleH4_5, .titleH4_6, .titleH4_7{
        margin: 4px;
    }
    .titleH4_7{
        text-decoration: underline;
        margin: 4px 0!important;
    }
    .adver4DivDiv{
        top: 16.5%;
        right: 9%;
    }
    /* .adver1DivImgDiv{
        width: 125px;
        height: 155px;
        margin-left: 30px;
        padding-top: 7%;
        border-radius: 12px;
        background: #AAEBEB;
        display: flex;
        text-align: center;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
    } */
    .adver1DivImgDiv {
        width: 200px;
        height: 210px;
        margin-left: -5px;
        /* padding-top: 8%; */
        border-radius: 12px;
        display: flex;
        text-align: center;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
    }
    .adver4DivTitleDiv {
        width: 145px;
        height: 125px;
        margin: auto;
        padding-top: 7%;
        border-radius: 12px;
        background: #FFC83C;
        display: flex;
        text-align: center;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
    }
    .gbImgCoursePC{
        width: 385px;
        display: none;
    }
    .gbImgCoursePCIcon{
        width: 50px;
        height: 50px;
        display: none;
    }
    .gbImgCoursePhone{
        margin: 4px;
        width: 85px;
        display: block;
    }
    .gbImgCoursePhoneCC02{
        margin: 4px;
        width: 95px;
        display: block;
    }
    .gbBottomRow2{
        height: 80px;
    }
    .congratesSecOne{
        width: 56%;
        padding: 32px;
        margin-left: 0;
        border-radius: 15px;
    }
    .congratesSecOne p{
        font-size: 20px;
        line-height: 48px;
    }
    .congratesSecTwo{
        position: relative;
        top: -40px;
        width: 62%;
        padding: 32px;
        margin-left: 38%;
        border-radius: 15px;
    }
    .congratesSecTwo p{
        font-size: 20px;
        line-height: 48px;
    }
    .congratesSecThree{
        position: relative;
        top: -21px;
        left: -70px;
        width: 72%;
        padding: 48px;
        margin-left: 22%;
        margin-bottom: 48px;
        border-radius: 15px;
    }
    .congratesSecThree p {
        font-size: 20px;
        line-height: 48px;
    }

    /* 舊版本 */
    .container_customMargin{
        box-sizing: border-box;
        padding: 0 10vw !important;
    }
    .StepsTitle{
        color: #1E8C96;
        font-family: 'Inter';
        font-style: normal;
    
        border-bottom:4px solid #1E8C96;
        box-sizing: border-box;
        margin-bottom: 2rem;
        padding: 0;
        text-align: center;
    }
    .StepsTitle p{
    box-sizing: border-box;
    margin: 0 auto .3rem  auto;  
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    }
    .StepsCardCol{
        flex-direction: column;
    }
    .StepsCard, .StepsCardShareCert{
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 0;
        padding: 0;
        /* border: 3px solid #AAEBEB;
        box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.03);
        border-radius: 10px; */
    }
    /* .StepsCardflex{
        display: flex;
        justify-content: space-between;
    } */
    .StepsCardTitle p{
        color: #FA9600;
        font-style: normal;
        font-weight: bolder;
        font-size: 24px;
        line-height: 36px;
    }
    .StepsCardDescription p{
        color: #585858;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 143.52%;
        font-family: 'Inter';
        padding-right: .5rem;
    }
    .StepsCardImgSec{
        width: 312px;
        margin-bottom: 32px;
    }
    .StepsCardImg{
        width: 100%;
    }
    .StepTwoCardDescription, .StepThreeCardDescription{
        padding: 0 1rem 0 1rem;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 143.52%;
    }
    .StepTwoCardDescription p, .StepThreeCardDescription p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 143.52%;
        color: #585858;
    }
    .StepThreeDivContent img{
        width: 120px;
        height: 120px;
    }
    .StepTwoFLEX{
    display: flex;
    justify-content: space-between;
    }
    .StepTwoP{
        margin: 0;
    }
    
    .StepTwoCardDescription a{
        text-decoration: none;
        color: #1400FF;
    }
    .StepTwoCardDescriptionOne{
        color: #585858;
    }
    .howtoShareCertificateLink{
        text-decoration: none;
        color: #1400FF;
    }
    .StepThreeDescriptionList{
        box-sizing: border-box;
        border-radius: 6px;
        padding: .5rem 1rem .5rem 0;
        color: #585858;
        font-size: 1rem;
        font-weight: 500;
    }
    .StepThreeDescriptionList li{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 143.52%;
        color: #585858;
    }
    .StepThreeDescriptionListSpan{
        color: #FA9600;
    }
    .StepThreeCopyFLEX{
        width: 100%;
    }
    .StepThreeCopy{
        flex: 11;
        padding: 14px;
        /* margin: 0 0 12px 32px; */
    }
    .StepThreeCopy p{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 143.52%;
    color: #000000;
    margin: 0;
    }
    
    .CopySec{
        flex: 3;
        width: 100%;
    }
    
    .CopyButton{
        height: 75%;
        font-family: 'Inter';
        font-style: normal;
        font-size: 20px;
        line-height: 143.52%;
        /* background: #1E8C96; */
        border-radius: 3px;
        text-align: center;
        padding: 3px 20px;
    }
    .CopyButton:hover{
        border: 2px solid #FFFFFF;
        background-color: #1E8C96;
        color: #FFFFFF;

    }
    .lookSbSay{
        border: none;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 143.52%;
        background: #1E8C96;
        border-radius: 3px;
        text-align: center;
        color: white;
        padding: 3px 20px;
    }
    .lookSbSay:hover{
        box-shadow: 1px 1px 10px -1px #1E8C96;

    }
    .StepsThreeWidth{
        width: fit-content;
        background-color: #EBEBEB;
    }
    /* .StepThreeCopyAtt button{
        margin-right: 5%;
    } */
    
    
    .StepsCardONEflex{
        box-sizing: border-box;
        display: flex;
        padding: .5rem .5rem 1rem .5rem;
    }
    .lastSEC{
        /* display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; */
        height: 50px;
        text-align: center;
        padding:  2.5rem 0 0 0 ;
    }
    .lastSECSPAN{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 2vw;
        line-height: 35px;
        color: #585858;
        margin-top: 10px;
    }
    .SubmitSec{
        box-sizing: border-box;
        text-align: center;
        width: fit-content;
        display: inline-block;
    }
    .SubmitButton{
        box-sizing: border-box;
        background-color: #FA9600;
        padding: 6px;
        width: 28vw;
        color: white;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 30px;
        border: none;
        border-radius: 3px;
    }
    .SubmitButton:hover{
        box-shadow: 1px 1px 10px -1px #FA9600;
    }
    
    .whereisCertificatePhone{
        display: none;
    }
    .howtoShareCertificatePhone{
        opacity: 0;
        font-size: 0;
        line-height: 0;
        height: 0;
    }
    .WarningSec{
        font-family: 'Inter';
        font-style: normal;
        margin: 0;
        padding: 3rem;
        text-align: center;
    }
    .WarningSec p{
        font-size: 16px;
        line-height: 22px;
        color: #585858;
        margin: 0;
        padding: 0;
    }
    }
    @media screen and (max-width: 825px) {
    .adver4DivDiv{
        top: 18.5%;
        right: 9%;
    }
    .adver1DivImgDiv {
        width: 200px;
        height: 210px;
        margin-left: -5px;
    }
    .adver1{
        top: 0%;
        left: 0%;
    }
    .adver3{
        top: 295px;
        right: 295px;
    }
    .adver4{
        top: 8%;
    }
    .right2Img{
        top: 80%;
    }
    }
        @media screen and (max-width: 767px) {
            .gbTop{
                margin: 0;
            }
            .gbMain{
                height: 81%;
                /* min-height: 1330px; */
            }
            .gbAntButtonCourseContent{
                font-size: 12px;
                padding: 0 5px;
            }
            .gbAntButtonCourseContent span{
                font-size: 12px;
            }
            .bannerColPC{
                padding-top: 0rem;
            }
            .bannerColPhone{
                padding-top: 0;
            }
            .layoutComp{
                display: none;
            }
            .layoutPhone{
                display: block;
                margin: 25px auto 0;
                width: 100%;
            }
            /* .titleH1{
                font-size: 28px!important;
            } */
            .titleH2, .titleH2_2{
                font-size: 20px!important;
            }
            .titleH3{
                font-size: 20px!important;
            }
            .titleH3_2{
                font-size: 13px!important;
                /* margin-bottom: 8px!important; */
                /* color: #33658A!important; */
            }
            .titleH3_3{
                width: fit-content;
                /* padding: 2px 5px;
                margin: 12px 0 0 0!important; */
            }
            .titleH3_4{
                width: fit-content;
                /* padding: 0 16px 16px; */
            }
            .titleH3_6{
                font-size: 24px!important;
            }
            .titleH5{
                font-size: 14px!important;
            }
            .activeTitleDivPC{
                display:none;
            }
            .activeTitleDivPhone{
                display:block;
                margin-top: 60px!important;
            }
            /* .titleH3_5{
                font-size: 20px!important;
                margin-bottom: 5px!important;
            } */
            .titleH4, .titleH4_2, .titleH4_3{
                font-size: 12px!important;
            }
            .titleH4_3{
                text-decoration: underline;
            }
            .twoMemberCard{
                width: 300px;
                margin-top: 0;
            }
            .gbAdd2cartPC{
                width: 300px;
                margin-top: 0;
            }
            .bannerSec{
                position: initial;
                padding: 12px 0;
            }
            .adver1Div{
                width: 100%;
                text-align: right;
            }
            .adver4Div{
                width: 100%;
            }
            .adver4DivDiv {
                /* top: 30.5%; */
                top: 320px;
                right: 22%;
            }
            .adver1DivImgDiv{
                position: relative;
                top: 15px;
                right: -120px;
                /* width: 175px;
                height: 200px; */
                width: 220px;
                height: 230px;
                border-radius: 10px;
            }
            .adver4DivTitleDiv {
                width: 130px;
                height: 125px;
                padding-top: 10%;
                border-radius: 10px;
            }
            .congratesSecOne{
                width: 91%;
                padding: 3% 5%;
                margin-left: 0;
                border-radius: 10px;
            }
            .congratesSecOne p{
                font-size: 16px;
                line-height: 24px;
                margin: 6px 0;
            }
            .congratesSecTwo{
                position: relative;
                top: 15px;
                right: 115px;
                width: 91%;
                padding: 3% 5% 3% 26%;
                margin-left: 156px;
                border-radius: 10px;
            }
            .congratesSecTwo p{
                font-size: 16px;
                line-height: 24px;
                margin: 6px 0;
            }
            .congratesSecThree{
                position: relative;
                top: 30px;
                left: -88px;
                width: 90%;
                padding: 3% 5%;
                margin-left: 88px;
                margin-bottom: 60px;
                border-radius: 10px;
            }
            .congratesSecThree p {
                font-size: 16px;
                line-height: 24px;
                margin: 6px 0;
            }
            .container_customMargin{
                margin: 0!important;
                padding: 0!important;
            }
            .bannerPC3{
                height: 100%;
                width: 100%;
                display: none;
            }
            .bannerPhone3{
                height: 100%;
                width: 100%;
                display: block;
            }
            .bannerBottomImg{
                margin: -18px 0px 0 85px;
            }
            /* .congratesSecOne{
                margin: auto;
                padding: 8px;
                box-sizing: border-box;
                margin-bottom: 1rem;
            }

            .congratesSecOne p{
                margin: 0;
                color: #585858;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 120.52%;
            }    
            .congratesSecTwo{
                margin: auto;
                box-sizing: border-box;
                margin-bottom: 1rem;
                padding: 8px;
            }

            .congratesSecTwo p{
                margin: 0;
                color: #585858;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 120.52%;
            }

            .congratesSecThree{
                color: #585858;
                box-sizing: border-box;
                padding: 8px;
                margin-bottom: 1rem;
            }
            .congratesSecThree p {
                margin: 0;
                color: #585858;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 120.52%;
            
            }
            .congratesSecFour{
                box-sizing: border-box;
                margin-bottom: 24px;
                padding: 8px;
                margin: 0 auto 1rem auto;
            }
            
            .congratesSecFour p{
                margin: 0;
                color: #1E8C96;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 120.52%;
            } */
            /* .StepsCardflex{
                display: flex;
                flex-direction: column;
            } */
            .StepsTitle{
                color: #1E8C96;
                border-bottom:4px solid #1E8C96;
                box-sizing: border-box;
                margin-bottom: 2rem;
                padding: 0;
                text-align: center;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 25px;
                line-height: 30px;
            }
            .StepsTitle p{
                box-sizing: border-box;
                margin: 0 auto .3rem  auto;  
                font-weight: 700;
                font-size: 25px;
                line-height: 30px;
            }
            .StepsCard, .StepsCardShareCert{
                width:100%;
                box-sizing: border-box;
                margin-bottom: 0.4rem;
                border: none;
                box-shadow: none;
                border-radius: none;
                padding: 0;
            }
            .StepsCardDescription p{
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 120.52%;
                color: #585858;
            }
            .SubmitSec{
                box-sizing: border-box;
            }
            /* .SubmitButton{
                box-sizing: border-box;
                padding: .5rem;
                background-color: #FA9600;
                width: 14rem;
                color: white;
                font-size: 1rem;
                font-weight: 700;
                border: none;
                border-radius: 3px;
            } */
            .SubmitButton{
                margin-top: 5px;
                padding: 2px 10px;
                width: 30vw;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
            } 
            .SubmitButton:hover{
                box-shadow: 1px 1px 10px -1px #FA9600;
            }
            .grid{
                text-align: start;
            }
            .StepsCardONEflex{
                display: flex;
                flex-direction: column;
                padding: 0;
            }
            /* .StepsCardDescription{
                border-left:8px hsla(180, 62%, 79%, 0.301) solid ;
                margin-left: 1rem;
                padding-left: .3rem;
            } */
            .StepTwoCardDescription, .StepThreeCardDescription{
                padding: 0;
            }
            .StepTwoCardDescription p, .StepThreeCardDescription p {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 120.52%;
                color: #585858;
            }
            .StepTwoCardDescriptionOne{
                font-family: 'Inter';
                font-style: normal;
                font-weight: 300;
                font-size: 16px !important;
                line-height: 120.52%;
                color: #585858; 
            }
            .StepThreeDivContent img{
                width: 75px;
                height: 75px;
            }
            .StepTwoFLEX{
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                display: flex;
                justify-content: space-between;
            }
            .StepTwoCardDescription a{
                text-decoration: none;
                color: #1400FF;
            }
            .StepThreeDescriptionList{
                box-sizing: border-box;
                background-color: #EBEBEB;
                border-radius: 6px;
                padding:  10px;
                color: #585858;
                width: fit-content;
            }
            .StepThreeDescriptionList li{
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 120.52%;
                color: #585858;
            }
            .StepsCardImgSec{
                width: 265px;
                /* margin-bottom: 20px; */
                display: inline-block; 
            }
            .StepsCardImg{
                width: 100%;
            }
            .StepThreePStar{
                margin-top: 16px;
            }
            .WarningSec{
                margin: 0;
                padding: 1.5rem;
                text-align: center;
            }
            .WarningSec p{
                font-size: 12px;
                line-height: 18px;
                margin: 0;
                padding: 0;
            }
            .StepsCardTitle  {
                color: #FA9600;
                margin-bottom: 1rem;
            }
            .StepsCardTitle p{
                margin-bottom: 0;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 161.52%;
                color: #FA9600;
            }
            .whereisCertificatePhone{
                display: block;
            }
            .whereisCertificatePC{
                display: none;
            }
            .StepThreeCopyAttTextPC{
                margin-top: 30px;
                display: none;
            }
            .StepThreeCopyAttTextPhone{
                display: block;
            }
            .howtoShareCertificatePhone{
                opacity: 1;
                height: 100%;
            }
            .howtoShareCertificatePC{
                display: none;
            }
            .StepTwoP{
                margin: 0 0 0 20px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                color: #585858;
            }
            .howtoShareCertificateLink{
                text-decoration: none;
                color: #1400FF;
            }
            .StepThreeCopyFLEX{
                margin-bottom: 20px;
            }
            .StepThreeCopy{
                flex: 10;
                padding: 8px;
                /* margin: 0 0 12px; */
            }
            .StepThreeCopy p{
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                /* line-height: 120.52%; */
                line-height: 18px;
                margin: 0;
                color: #000000;
            }
            .CopySec{
                width: 100%;
            }
            .CopyButton{
                height: 75%;
                font-style: normal;
                font-size: 12px;
                line-height: 120.52%;
                border-radius: 3px;
                text-align: center;
                /* padding: 8px 15px; */
                
            }
            .lookSbSay{
                border: none;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 300;
                font-size: 12px;
                line-height: 120.52%;
                color: white;
                border-radius: 3px;
                background: #1E8C96;
                text-align: center;
                /* padding: 8px 15px; */
                
            }
            .lookSbSay:hover{
                box-shadow: 1px 1px 10px -1px #1E8C96;

            }
            .StepThreeCopyAtt p{
                margin-bottom: 0;
            }
            .StepThreeCopyAtt button {
                /* margin-right: 8%; */
                font-size: 12px;
                padding: 4px;
            }
            .lastSEC{
                height: 48px;
                text-align: center;
                margin-top: 16px;
                padding:0 ;
                display: flex;
                /* flex-direction: column; */
                justify-content: space-evenly;
            
            }
            .lastSECSPAN{
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 25px;
                line-height: 30px;
                color: #585858;
                margin-top: 10px;
            
            }   
            /* }
            @media screen and (max-width:560px) { */
            .adver1DivImgDiv{
                right: -56px;
                margin: 0;
            }
            .adver1{
                left: 2px;
            }
            .adver4DivDiv {
                right: 8%;
            }
            .adver4{
                right: 0%;
            }
            .right2Img{
                right: -8%;
            }
        }
        /* @media screen and (max-width:540px) {
        .adver4DivDiv {
            left: 190px
        }
        .adver4{
            left: 225px;
        }
        } */
        @media screen and (max-width:490px) {
        .adver1DivImgDiv{
            left: 45px;
        }
        .adver1{
            left: 4px;
        }

        }
        /* @media screen and (max-width: 385px) {
            .gbMain{
                min-height: 1400px;
            }
        }
        @media screen and (max-width: 365px) {
            .gbMain{
                min-height: 1438px;
            }
        } */
/* @media screen and (max-width:420px) {
    .adver1DivImgDiv{
        left: 45px;
    }
    .adver1{
        left: 25px;
    }
} */

/* 電腦版 */
/* @media only screen and (min-width: 967px){
} */
/* 平板 */
/* @media only screen and (min-width: 767px) and (max-width: 967px){
} */

/* 手機 */
/* @media only screen and (min-width: 0px) and (max-width: 767px){
} */



