.site-footer{
    background-color: #dadada;
    padding: 32px 12px 20px;
	width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
}
.site-footer-container{
	width: 100%;
	display: flex;
    justify-content: space-evenly;
}
.site-footer div, span, p{
    font-size: 16px;
}
.site-primary-footer-wrap{
    margin: 2px 0 0;
}
/* 使用者資訊條款...區塊 */
.site-footer-section-1{
	display: flex;
	width: fit-content;
}
/* 社會責任、徵才資訊...區塊 */
.site-footer-section-2 {
    display: flex;
	width: fit-content;
}
.ast-builder-footer-grid-columns.site-below-footer-inner-wrap.ast-builder-grid-row {
	/* max-width: 70%; */
	margin: auto;
	padding: 0 32px 0 32px;
}
.ast-builder-footer-grid-columns.site-primary-footer-inner-wrap.ast-builder-grid-row {
	/* max-width: 70%; */
	margin: auto;
	padding: 0 10px 0 70px;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
}
/* footer各格比例 */

.ast-builder-grid-row-3-rheavy .ast-builder-grid-row {
	grid-template-columns: 1fr 1fr 6fr;
}
/* footer1 */

.site-navigation .astra-footer-vertical-menu {
	display: grid;
	justify-content: end;
	list-style-type: none;
}
.ast-nav-menu{
	list-style-type: none;
    padding-left: 0;
	margin-bottom: 0;
    width: fit-content;
}
.menu-item{
	margin: 0 12px;
}
.site-navigation .astra-footer-vertical-menu a{
    color: #fa9600!important;
	text-shadow: 1px 1px 2px #FFF;
}
.footer-2 {
	color: #fa9600!important;
	text-shadow: 1px 1px 2px #FFF;
}
.footer-2:hover {
	color: #ffc83c!important;
}
/* 第二個footer wrap */
.site-below-footer-wrap{
	width: initial;
}
.footer-widget-area.widget-area.site-footer-focus-item.ast-footer-html-2 {
	/* margin: 0 0 0 20%; */
	/*height: 150px;*/
	white-space: nowrap;
}
@media screen and ( max-width: 1780px) {
	.ast-builder-footer-grid-columns.site-below-footer-inner-wrap.ast-builder-grid-row {
		max-width: 100%;
		margin: auto;
	}
	.ast-builder-footer-grid-columns.site-primary-footer-inner-wrap.ast-builder-grid-row {
		max-width: 100%;
		margin: auto;
	}
	/* footer1 */
	.site-navigation .astra-footer-vertical-menu {
		display: grid;
		justify-content: end;
	}
	.footer-2 {
		color: #fa9600!important;
	}
	.footer-2:hover {
		color: #ffc83c!important;
	}
}
@media screen and ( max-width: 967px) {
	.site-footer{
        padding: 20px 12px 12px;
        display: flex;
        flex-direction: column;
    }
	.site-footer div, span, p{
        font-size: 12px;
    }
	.site-footer-container{
		flex-direction: column;
	}
    .site-primary-footer-wrap{
        width: 100%;
        margin: 0 0 12px!important;
    }
    .ast-builder-footer-grid-columns.site-primary-footer-inner-wrap.ast-builder-grid-row{
        padding: 0!important;
		/* padding: 0 35px 0 35px!important; */
		justify-content: center!important;
    }
	/* footer grid 改 flex 可換行*/
	.ast-builder-footer-grid-columns.site-below-footer-inner-wrap.ast-builder-grid-row {
		/* padding: 0px 10px 8px; */
		padding: 0;
		max-width: 445px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
	}
	/* 使用者條款.....華山中心 */
	.ast-builder-footer-grid-columns.site-primary-footer-inner-wrap.ast-builder-grid-row {
		/* padding: 0 35px 0 35px; */
		/* margin: auto;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: center; */
	}
	/* footer各格比例 */
	.ast-builder-grid-row-3-rheavy .ast-builder-grid-row {
		/* grid-template-columns: 1fr 1fr 3fr!important; */
	}
	/* 社會責任...向前對齊 */
	.footer-nav-wrap .astra-footer-vertical-menu {
		/* padding: 0 0 0 20px; */
		/* display: grid;
		justify-content: start;
		margin-bottom: 0; */
	}
	/* 社會責任...向前對齊 */
	.footer-nav-wrap .astra-footer-vertical-menu {
		padding: 0;
		display: flex;
	}
	li.menu-item{
		margin: 0 8px 0 0;
	}
	/* footer2 margin */
	.footer-widget-area.widget-area.site-footer-focus-item.ast-footer-html-2 {
		/* margin: 0 0 0 20%; */
		/* margin: 0;
		height: 100%; */
	}

	/* copy right div */
	.site-below-footer-wrap{
		width: 100%;
		display: flex;
		justify-content: center;
	} 
	.ast-builder-grid-row-container-inner{
		width: 100%;
	}
}
/* @media screen and ( max-width: 768px) {
	.site-footer-container{
		flex-direction: column;
	}
} */
@media screen and ( max-width: 540px) {
	/* footer grid 改 flex 可換行 */
	.ast-builder-footer-grid-columns.site-below-footer-inner-wrap.ast-builder-grid-row {
		padding: 0px 20px 8px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
	}
	/* .ast-builder-footer-grid-columns.site-primary-footer-inner-wrap.ast-builder-grid-row {
		padding: 0px 20px 8px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
	} */
	/* footer2 margin */
	.footer-widget-area.widget-area.site-footer-focus-item.ast-footer-html-2 {
		margin: 0 0 0 5%;
		/* height: 162px; */
	}
}