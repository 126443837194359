.ModalBody{
    background-color: white;
    border-radius: 3px;
}
.ModalTitle{
    color: #585858;
    font-weight: 600;
    font-size: 25px;
   line-height: 36px;
   text-align: center;
}
.ModalError{
    color: #585858;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    text-align: center;
}
.ModalFooter{
    background-color: #FFF096;
}
.ModalCloseSec{
    width: 100%;
    text-align: center;
}
.ModalClose{
    border: none;
    background: #1E8C96;
    border-radius: 3px;
    color: white;
    padding: 0.3rem 1rem;
}